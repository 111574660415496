import React from "react";
import Confetti from "react-dom-confetti";
import "./ThankYou.scss";

class ThankYouEn extends React.PureComponent {
  state = {
    confetti: false,
  };

  confettiConfig = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 3500,
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  componentDidMount = () => {
    this.setState({ confetti: true });
  };

  render() {
    const { confetti } = this.state;
    return (
      <div id="thank-you">
        <Confetti
          className="confetti"
          active={confetti}
          config={this.confettiConfig}
        />
        <img alt="" src={require("../assets/ves_logo_dark.svg")} />
        <h1>
          Payment successful, thank you for your order!{" "}
          <span role="img" aria-labelledby="emoji">
            🎉
          </span>
        </h1>
        <h2>
          The passcode and the receipt have been sent to your e-mail! You can
          now close this tab.
        </h2>
        <hr />
        <div>
          <p>
            If you have any problems, please read our{" "}
            <a href="/faq" target="_blank" rel="noopener noreferrer">
              frequently asked questions
            </a>
            .
          </p>
          <p>
            If you can't find a solution, please contact us at:{" "}
            <a href="mailto:support@virtualeventstudio.fi">
              support@virtualeventstudio.fi
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default ThankYouEn;
