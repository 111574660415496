import Axios from "axios";
import React from "react";
import { BarLoader } from "react-spinners";
import styles from "./FAQ.module.scss";

class FAQ extends React.PureComponent {
  state = {
    email: "",
    eventKey: "",
    sending: false,
    successText: "",
    errorText: "",
  };
  _getFaqs = () => [
    {
      question:
        "Mitä tehdä, kun tilasin ja maksoin koodin, mutta se ei saapunut koskaan sähköpostiini?",
      answer: (
        <>
          <p>
            Sähköpostiviestillä voi mennä muutama minuutti, ennen kuin se saapuu
            perille. Tarkistathan varmuuden vuoksi myös sähköpostisi
            roskapostilaatikon sekä sen, ettei sähköpostilaatikkosi ole täynnä.
          </p>
          <p>
            Mikäli sähköpostia ei kuulu eikä näy, kirjoitathan alle
            sähköpostiosoitteen, jolla tilaus on tehty. Lähetä sähköposti
            uudelleen -nappia painamalla lähetämme koodin ja kuitin
            sähköpostiisi uudestaan.
          </p>
          <p>
            Tapahtuman avain on kauttaviivan jälkeinen URL-osoitteen loppuosa,
            esim. live.virtualeventstudio.fi/<strong>tapahtuman_avain</strong>.
          </p>
          {this.state.successText && (
            <p className={[styles.statusText, styles.green].join(" ")}>
              {this.state.successText}
            </p>
          )}
          {this.state.errorText && (
            <p className={[styles.statusText, styles.red].join(" ")}>
              {this.state.errorText}
            </p>
          )}
          <div className={styles.resend}>
            <input
              onChange={this._setEventKey}
              value={this.state.eventKey}
              placeholder="Kirjoita tapahtuman avain"
            />
            <input
              onChange={this._setEmail}
              value={this.state.email}
              placeholder="Kirjoita tilaussähköpostisi"
              onKeyDown={(e) => {
                if (!this.state.eventKey || !this.state.email) {
                  return;
                }
                if (e.key === "Enter") {
                  this._resendMail();
                }
              }}
            />
            {this.state.sending ? (
              <button disabled={true}>
                <BarLoader loading={true} color="white" margin={10} />
              </button>
            ) : (
              <button
                className={
                  (!this.state.eventKey || !this.state.email) && styles.disabled
                }
                disabled={!this.state.eventKey || !this.state.email}
                onClick={this._resendMail}
              >
                Lähetä sähköposti uudelleen
              </button>
            )}
          </div>
        </>
      ),
    },
    {
      question: "Toimiiko palvelu selaimellani / laitteellani?",
      answer: (
        <>
          <p>
            Livestreamin katselu vaatii nopean verkkoyhteyden sekä
            mobiililaitteen tai tietokoneen. Emme voi valitettavasti taata
            palvelun toimivuutta Smart-TV-malleilla.
          </p>
          <p>
            Jotta video ei pätkisi katseltaessa, tarvitset vähintään
            5Mbps-latausnopeuteen kykenevän verkkoyhteyden. Suosittelemme
            käyttöön vähintään 10Mbps-yhteyttä. Voit testata oman
            yhteysnopeutesi esimerkiksi tällä palvelulla:{" "}
            <a
              href="https://www.speedtest.net"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.speedtest.net
            </a>
            . Klikkaa “GO”-näppäintä ja odota, että nopeustesti antaa tulokset.
          </p>
          <p>
            Suosittelemme käyttämään katseluun Google Chrome -selainta.
            Livevideo ei välttämättä toistu vanhemmilla selaimilla.
          </p>
        </>
      ),
    },
    {
      question: "Video tökkii, mikä avuksi?",
      answer: (
        <>
          <p>
            Varmista, että käytössäsi on nopea verkkoyhteys, teräväpiirtokuva
            vaatii paljon internetiltä. Voit testata oman internet-yhteytesi
            nopeuden ylemmän kohdan ohjeiden mukaisesti. Suosittelemme
            latausnopeudeksi vähintään 10Mbps-internetyhteyttä.
          </p>
          <p>
            Mikäli internet-yhteys on nopea ja suosituksen mukainen, kokeile
            ensin sulkea selain ja avata sivu uudelleen. Sulje tietokoneelta /
            mobiililaitteelta kaikki ylimääräiset sovellukset ja välilehdet.
          </p>
          <p>
            Voit myös säätää videon tarkkuutta videolaatikon alalaidasta
            löytyvällä mutteri-säätimellä. Täysi teräväpiirtokuva 1080p vaatii
            eniten internet-yhteydeltä, kokeile pienempää tarkkuutta esimerkiksi
            720p.
          </p>
          <p>
            Mikäli nämä ohjeet eivät auta, yritä avata sivu toisella laitteella
            tai ota yhteys asiakaspalveluumme{" "}
            <a href="mailto:support@virtualeventstudio.fi">
              support@virtualeventstudio.fi
            </a>
            .
          </p>
        </>
      ),
    },
    {
      question: "Näen videon, mutta en kuule ääntä?",
      answer: (
        <p>
          Tarkasta, että tietokoneesta / mobiililaitteesta on säädetty ääni
          riittävän kovalle. Joillakin selaimilla videoikkuna mykistää
          lähtökohtaisesti itsensä, jolloin videolaatikon kulmaan ilmestyy
          “Mute”-nappula. Sitä painamalla mykistys lähtee pois päältä. Varmista
          myös, että videolaatikon alapalkista löytyvä äänenvoimakkuus-säätö on
          asetettu täydelle voimakkuudelle.
        </p>
      ),
    },
    {
      question: "Tapahtuma on alkanut, mutta video ei pyöri?",
      answer: (
        <p>
          Kokeile ensin päivittää sivu. Mikäli tämä ei auta, kokeile toista
          selainta (esimerkiksi Google Chrome). Tarkasta myös tapahtuman
          aikataulusta, ettei ohjelmassa ole tauko meneillään. Mikäli nämä eivät
          auta, ota yhteys asiakaspalveluumme{" "}
          <a href="mailto:support@virtualeventstudio.fi">
            support@virtualeventstudio.fi
          </a>
          .
        </p>
      ),
    },
    {
      question:
        "Miksi en pääse tilaamaan lippua (Siirry tilaamaan -painike on harmaa)?",
      answer: (
        <p>
          Siirry tilaamaan -painike aktivoituu, kun sähköposti on kirjoitettu
          oikeassa muodossa. Mikäli nappi on harmaa, tarkistathan kirjoittamasi
          sähköpostiosoitteen virheiden varalta.
        </p>
      ),
    },
    {
      question:
        "Kirjauduin sisään useamman kerran samalla pääsykoodilla, ja yksi laitteistani kirjattiin ulos. Miksi näin?",
      answer: (
        <>
          <p>
            Se, kuinka monta kertaa tapahtumaa voidaan katsella samalla
            pääsykoodilla riippuu ostettujen lippujen määrästä sekä asiakkaan
            määrittämästä yhtäaikaisten katselujen määrästä.
          </p>
          <p>
            Esim. jos ostettuja lippuja on kaksi, ja yhdellä lipulla sallitaan
            katselu kahdella laitteella, voi tuolla pääsykoodilla kirjautua
            sisään yhteensä neljä kertaa.
          </p>
          <p>
            Esimerkkitapauksessa kun sisään kirjaudutaan viidennellä laitteella,
            kirjataan ensimmäisenä sisäänkirjautunut laite ulos. Näin ollen
            sisäänkirjautuminen toimii aina, mutta aiempia laitteita kirjataan
            ulos niin, että yhtäaikaisten katselujen määrä pysyy sallituissa
            rajoissa.
          </p>
        </>
      ),
    },
  ];

  _setEventKey = (e) => {
    this.setState({ eventKey: e.target.value });
  };

  _setEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  _resendMail = () => {
    this.setState({ sending: true, successText: "", errorText: "" }, () => {
      const data = new FormData();
      data.append("eventKey", this.state.eventKey);
      data.append("email", this.state.email);
      const url =
        process.env.NODE_ENV === "development"
          ? "http://localhost:8000/server/resend.php"
          : process.env.PUBLIC_URL + "/resend.php";
      Axios.post(url, data)
        .then(() => {
          this.setState({
            sending: false,
            successText: "Sähköposti lähetetty onnistuneesti.",
          });
        })
        .catch((err) => {
          if (err.response && err.response.data.error === "not_found") {
            this.setState({
              sending: false,
              errorText:
                "Sähköpostilla ei löytynyt tilauksia valitsemaasi tapahtumaan.",
            });
          } else {
            this.setState({
              sending: false,
              errorText: "Sähköpostin lähetys epäonnistui.",
            });
          }
        });
    });
  };

  render() {
    return (
      <div id={styles.faq}>
        <img alt="" src={require("../assets/ves_logo_dark.svg")} />
        <h1>Usein kysytyt kysymykset</h1>
        {this._getFaqs().map((faq, i) => {
          return (
            <div key={i} className={styles.question}>
              <h2>{faq.question}</h2>
              {faq.answer}
              <hr />
            </div>
          );
        })}
        <div>
          <p>
            Muissa ongelmatilanteissa otathan yhteyttä osoitteeseen{" "}
            <a href="mailto:support@virtualeventstudio.fi">
              support@virtualeventstudio.fi
            </a>
            .
          </p>
        </div>
      </div>
    );
  }
}

export default FAQ;
