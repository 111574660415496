import React from "react";
import { Switch, TextField } from "@material-ui/core";
import JoditEditor from "jodit-react";
import { cloneDeep, isEqual } from "lodash";
import styles from "./EventDetails.module.scss";
import FirebaseHelper from "../../FirebaseHelper";
import { withSnackbar } from "notistack";

class EventDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    const joditButtons = ["paragraph", "bold", "italic", "link"];
    this.joditConfig = {
      buttons: joditButtons,
      buttonsXS: joditButtons,
      buttonsSM: joditButtons,
      buttonsMD: joditButtons,
    };
    const eventData = cloneDeep(this.props.event);
    const schedule = eventData.schedule || {};
    const scheduleArr = Object.keys(schedule).map((key) => ({
      time: key,
      label: schedule[key],
    }));
    eventData.schedule = scheduleArr;
    this.originalEventData = cloneDeep(eventData);
    this.state = {
      scheduleListMode: !eventData.schedule_url,
      eventData,
    };
  }

  onEventDataChanged = (e) => {
    const eventData = {
      ...this.state.eventData,
      [e.target.name]: e.target.value,
    };
    this.setState({ eventData });
  };

  toggleScheduleMode = () => {
    this.setState((prevState) => ({
      scheduleListMode: !prevState.scheduleListMode,
    }));
  };

  editScheduleItem = (e, i, type) => {
    const schedule = [...this.state.eventData.schedule];
    schedule[i][type] = e.target.value;
    this.setState({
      eventData: {
        ...this.state.eventData,
        schedule,
      },
    });
  };

  removeScheduleItem = (i) => {
    const schedule = [...this.state.eventData.schedule];
    schedule.splice(i, 1);
    this.setState({
      eventData: {
        ...this.state.eventData,
        schedule,
      },
    });
  };

  addScheduleRow = () => {
    const schedule = [...this.state.eventData.schedule];
    schedule.push({ time: "", label: "" });
    this.setState({
      eventData: {
        ...this.state.eventData,
        schedule,
      },
    });
  };

  setScheduleURL = (e) => {
    const eventData = {
      ...this.state.eventData,
      schedule_url: e.target.value,
    };
    this.setState({ eventData });
  };

  save = () => {
    const {
      eventData: {
        description = "",
        name = " ",
        schedule = {},
        schedule_url = "",
        chat_disabled = false,
      },
    } = this.state;
    const scheduleObj = {};
    schedule.forEach((item) => {
      scheduleObj[item.time] = item.label;
    });
    const data = {
      description,
      name,
      schedule: scheduleObj,
      schedule_url,
      chat_disabled,
    };
    this.setState({ saving: true }, () => {
      FirebaseHelper.editEvent(
        data,
        this.props.event,
        !!this.props.event.sub_events
      ).then(() => {
        this.originalEventData = cloneDeep(this.state.eventData);
        this.setState({ saving: false });
        this.props.enqueueSnackbar("Muutokset tallennettu!", {
          variant: "success",
        });
      });
    });
  };

  renderScheduleSection = () => {
    const {
      scheduleListMode,
      eventData: { schedule, schedule_url },
    } = this.state;
    return (
      <div className={styles.editSection}>
        <div className={styles.title}>
          <h2>Tapahtuman aikataulu</h2>
          <button
            className={styles.subButton}
            onClick={this.toggleScheduleMode}
          >
            {scheduleListMode
              ? "Käytä aikataululinkkiä"
              : "Käytä aikataululistaa"}
          </button>
        </div>
        {scheduleListMode ? (
          <div className={styles.scheduleList}>
            {schedule.map((item, i) => {
              return (
                <div key={i} className={styles.scheduleRow}>
                  <input
                    onChange={(e) => this.editScheduleItem(e, i, "time")}
                    value={item.time}
                  />
                  <input
                    onChange={(e) => this.editScheduleItem(e, i, "label")}
                    value={item.label}
                  />
                  <button
                    className={`${styles.mainButton} ${styles.delete}`}
                    onClick={() => this.removeScheduleItem(i)}
                  >
                    Poista
                  </button>
                </div>
              );
            })}
            <button
              className={`${styles.mainButton} ${styles.add}`}
              onClick={this.addScheduleRow}
            >
              Lisää rivi
            </button>
          </div>
        ) : (
          <textarea
            spellCheck={false}
            draggable={false}
            value={schedule_url}
            onChange={this.setScheduleURL}
            placeholder="Kirjoita aikataulun URL-osoite tähän."
          ></textarea>
        )}
      </div>
    );
  };

  render() {
    const {
      eventData: { description, name, chat_disabled },
    } = this.state;
    return (
      <div id={styles.eventDetails}>
        <div className={styles.top}>
          <button
            disabled={isEqual(this.originalEventData, this.state.eventData)}
            onClick={this.save}
          >
            Tallenna muutokset
          </button>
        </div>
        <div className={styles.editSection}>
          <TextField
            variant="outlined"
            size="small"
            label="Tapahtuman nimi"
            name="name"
            onChange={this.onEventDataChanged}
            value={name}
          />
          <div className={styles.row}>
            <Switch
              onChange={(e) =>
                this.onEventDataChanged({
                  target: { name: "chat_disabled", value: !e.target.checked },
                })
              }
              checked={!chat_disabled}
            />
            <p>Chat on käytössä</p>
          </div>
        </div>
        <div className={styles.editSection}>
          <h2>Tapahtuman kuvaus</h2>
          <JoditEditor
            value={description}
            config={this.joditConfig}
            onChange={(value) =>
              this.onEventDataChanged({
                target: { name: "description", value },
              })
            }
          />
        </div>
        {this.renderScheduleSection()}
      </div>
    );
  }
}

export default withSnackbar(EventDetails);
