import React from "react";
import moment from "moment";
import { cloneDeep } from "lodash";
import FirebaseHelper from "../../FirebaseHelper";
import styles from "./PollView.module.scss";
import LocalizationManager from "../../LocalizationManager";

const Poll = (props) => {
  const {
    poll,
    onAddOption,
    onEditPoll,
    onEditPollOption,
    sendPoll,
    sendPollAnswers,
    event,
    index,
  } = props;
  return (
    <div className={styles.poll}>
      <input
        onChange={(e) => onEditPoll("question", e.target.value, index)}
        placeholder={LocalizationManager.localize("Kirjoita kysymys...", event)}
        value={poll.question}
      />
      {poll.key && (
        <div className={styles.topButtons}>
          <button
            onClick={() => sendPoll(poll)}
            className={`${styles.showPollButton} ${styles.green}`}
          >
            {LocalizationManager.localize("Lähetä kysely chattiin", event)}
          </button>
          <button
            onClick={() => sendPollAnswers(poll)}
            className={`${styles.showPollButton} ${styles.green}`}
          >
            {LocalizationManager.localize("Lähetä vastaukset chattiin", event)}
          </button>
        </div>
      )}
      <h2>{LocalizationManager.localize("Vastausvaihtoehdot", event)}</h2>
      <div className={styles.options}>
        {poll.options &&
          poll.options.map((option, i) => {
            return (
              <div className={styles.option}>
                <p>{i + 1}.</p>
                <input
                  placeholder={LocalizationManager.localize(
                    "Kirjoita vaihtoehto...",
                    event
                  )}
                  onChange={(e) => onEditPollOption(e.target.value, index, i)}
                  value={option.title}
                />
                <p className={styles.counts}>
                  {LocalizationManager.localize("Ääniä:", event)}{" "}
                  {option.count || 0}
                </p>
              </div>
            );
          })}
        <button onClick={() => onAddOption(index)}>
          {LocalizationManager.localize("+ Lisää vaihtoehto", event)}
        </button>
      </div>
    </div>
  );
};

class PollView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      shownPoll: this.props.event.shown_poll,
    };
  }

  componentDidMount = () => {
    FirebaseHelper.getPolls(this.props.event).then((res) => {
      const polls = Object.values(res || {});
      this.setState({ polls });
    });
    FirebaseHelper.onPollsChanged(this.props.event, (snapshot) => {
      const polls = Object.values(snapshot.val() || {});
      this.setState({ polls });
    });
  };

  _addPoll = () => {
    const polls = cloneDeep(this.state.polls);
    polls.push({});
    this.setState({ polls });
  };

  getPolls = () => {
    return this.state.polls;
  };

  onEditPoll = (key, value, index) => {
    const polls = cloneDeep(this.state.polls);
    polls[index] = {
      ...polls[index],
      [key]: value,
    };
    this.setState({ polls });
  };

  onEditPollOption = (value, pollIndex, optionIndex) => {
    const polls = cloneDeep(this.state.polls);
    const options = [...(polls[pollIndex].options || [])];
    options[optionIndex] = {
      ...options[optionIndex],
      title: value,
    };
    polls[pollIndex].options = options;
    this.setState({ polls });
  };

  onAddOption = (index) => {
    const polls = cloneDeep(this.state.polls);
    const options = [...(polls[index].options || [])];
    options.push({});
    polls[index].options = options;
    this.setState({ polls });
  };

  sendPoll = (poll) => {
    const data = {
      is_customer: true,
      message: "Päivitä sivu nähdäksesi kyselyn.",
      utc: true,
      timestamp: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
      poll,
    };
    FirebaseHelper.sendMessage(data, this.props.event);
  };

  sendPollAnswers = (poll) => {
    const data = {
      is_customer: true,
      message: "Päivitä sivu nähdäksesi kyselyn.",
      utc: true,
      timestamp: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
      poll: poll,
      displayAnswers: true,
    };
    FirebaseHelper.sendMessage(data, this.props.event);
  };

  render() {
    const { polls } = this.state;
    return (
      <div id={styles.pollView}>
        <div className={styles.titles}>
          <h1>{LocalizationManager.localize("Kyselyt", this.props.event)}</h1>
          <p>
            {LocalizationManager.localize(
              "Tässä näkymässä voit muokata kyselyitä ja näyttää niitä streamin viereisessä chatissa.",
              this.props.event
            )}
          </p>
        </div>
        {!!polls && (
          <div className={styles.polls}>
            {polls.map((poll, i) => {
              return (
                <Poll
                  event={this.props.event}
                  onEditPoll={this.onEditPoll}
                  onEditPollOption={this.onEditPollOption}
                  onAddOption={this.onAddOption}
                  poll={poll}
                  sendPoll={this.sendPoll}
                  sendPollAnswers={this.sendPollAnswers}
                  key={i}
                  index={i}
                />
              );
            })}
          </div>
        )}
        <button className={`${styles.mainButton} ${styles.add}`} onClick={this._addPoll}>
          {LocalizationManager.localize("+ Lisää kysely", this.props.event)}
        </button>
      </div>
    );
  }
}

export default PollView;
