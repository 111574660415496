import Axios from "axios";
import LocalizationManager from "./LocalizationManager";

const randomNumberString = (length) => {
  var result = "";
  var characters = "0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const initPayment = async (event, email, ticketData, language) => {
  const formattedKey = event.key.split("_")[0];
  const stamp =
    "ves_payment" +
    randomNumberString(5) +
    "_" +
    formattedKey +
    "_" +
    new Date().toISOString();
  const reference = "VES" + randomNumberString(10);
  const nonce = randomNumberString(15);
  let response;
  const data = new FormData();
  let tickets = [];
  let total = 0;
  Object.keys(ticketData).forEach((key) => {
    total += Number(ticketData[key].price) * Number(ticketData[key].quantity);
    tickets.push({
      ...ticketData[key],
      price: (Number(ticketData[key].price) * 100).toFixed(2),
      product_code: key,
    });
  });
  data.append("stamp", stamp);
  data.append("reference", reference);
  data.append("totalAmount", (total * 100).toFixed(2));
  data.append("tickets", JSON.stringify(tickets));
  data.append("nonce", nonce);
  data.append("language", language);
  data.append("email", email);
  data.append("eventKey", formattedKey);
  try {
    response = await Axios.post(
      process.env.NODE_ENV === "development"
        ? "http://localhost:8000/server/handlePayment.php"
        : process.env.PUBLIC_URL + "/handlePayment.php",
      data
    );
  } catch (e) {
    alert(
      LocalizationManager.localize("Maksutapojen haku epäonnistui.", event)
    );
    return null;
  }

  return response.data;
};
