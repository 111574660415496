import React, { useState } from "react";
import "./EventTabs.scss";
import ArtistInfo from "../../components/ArtistInfo/ArtistInfo";

const EventTabs = (props) => {
  const [mode, setMode] = useState("info");
  const { event } = props;
  return (
    <div id="event-tabs">
      <div className="tabs">
        <div
          onClick={() => setMode("info")}
          className={mode === "info" ? "active" : ""}
        >
          Kuvaus
        </div>
      </div>
      <div className="inner-content">
        {mode === "info" ? (
          <ArtistInfo event={event} />
        ) : null}
      </div>
    </div>
  );
};

export default EventTabs;
