import React from "react";
import {
  MdChat,
  MdEdit,
  MdList,
  MdLock,
  MdPieChart,
  MdPoll,
  MdRemoveRedEye,
} from "react-icons/md";
import TopBar from "../../components/TopBar/TopBar";
import FirebaseHelper from "../../FirebaseHelper";
import Event from "../Event/Event";
import Analytics from "./Analytics";
import Codes from "./Codes";
import EventDetails from "./EventDetails";
import LargeChat from "./LargeChat";
import styles from "./NewAdmin.module.scss";
import PollView from "./PollView";
import Registrations from "./Registrations";

class Admin extends React.PureComponent {
  sidebarItems = [
    {
      key: "event",
      title: "Katsele tapahtumaa",
      icon: <MdRemoveRedEye />,
    },
    {
      key: "edit",
      title: "Tapahtuman tiedot",
      icon: <MdEdit />,
    },
    {
      key: "codes",
      title: "Pääsykoodien hallinta",
      icon: <MdLock />,
    },
    {
      key: "analytics",
      title: "Analytiikka",
      icon: <MdPieChart />,
    },
    {
      key: "polls",
      title: "Kyselyt",
      icon: <MdPoll />,
    },
    {
      key: "registrations",
      title: "Ilmoittautumiset",
      icon: <MdList />,
    },
    {
      key: "chat",
      title: "Esiintyjän chat",
      icon: <MdChat />,
    },
  ];

  constructor(props) {
    super(props);
    this.pollView = React.createRef();
    this.state = {
      currentView: this.getCurrentViewFromLS(),
    };
  }

  getCurrentViewFromLS = () => {
    let tab = "event";
    try {
      tab = localStorage.getItem("ves_selected_admin_tab") || "event";
    } catch (err) {}
    return tab;
  };

  renderContentForView = (view) => {
    switch (view) {
      case "event":
        return <Event admin={true} event={this.props.event} />;
      case "edit":
        return <EventDetails event={this.props.event} />;
      case "codes":
        return <Codes event={this.props.event} />;
      case "analytics":
        return <Analytics event={this.props.event} />;
      case "chat":
        return <LargeChat event={this.props.event} />;
      case "polls":
        return <PollView ref={this.pollView} event={this.props.event} />;
      case "registrations":
        return <Registrations event={this.props.event} />;
      default:
        return null;
    }
  };

  setCurrentView = (currentView) => {
    this.setState({ currentView });
    try {
      localStorage.setItem("ves_selected_admin_tab", currentView);
    } catch (err) {}
  };

  onLogout = () => {
    FirebaseHelper.logOut();
  };

  savePolls = () => {
    const polls = this.pollView.current && this.pollView.current.getPolls();
    this.setState({ saving: true }, () => {
      FirebaseHelper.savePolls(polls, this.props.event).then(() => {
        this.setState({ saving: false });
      });
    });
  };

  render() {
    const { currentView, saving } = this.state;
    const { event, resetSubEvent } = this.props;
    return (
      <div id={styles.admin}>
        <TopBar
          admin={true}
          onLogOut={this.onLogout}
          resetSubEvent={event.sub_events && resetSubEvent}
          saving={saving}
          onSave={currentView === "polls" ? this.savePolls : undefined}
        />
        <div className={styles.content}>
          <div className={styles.sidebar}>
            <ul>
              {this.sidebarItems.map((item) => {
                if (!event.use_registration && item.key == "registrations")
                  return null;
                return (
                  <li
                    onClick={() => this.setCurrentView(item.key)}
                    className={`${
                      currentView == item.key ? styles.selected : ""
                    }`}
                  >
                    {item.icon}
                    <p>{item.title}</p>
                    <div className={styles.bottomBorder} />
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={styles.mainContent}>
            {this.renderContentForView(currentView)}
          </div>
        </div>
      </div>
    );
  }
}

export default Admin;
