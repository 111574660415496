import React from "react";
import Chat from "../../components/Chat/Chat";
import FirebaseHelper from "../../FirebaseHelper";
import styles from "./LargeChat.module.scss";

class LargeChat extends React.PureComponent {
  state = {
    loadingMessages: true,
    messages: [],
    polls: [],
    reactions: {
      heart: 0,
      fire: 0,
      clap: 0,
    },
  };

  _chat = React.createRef();

  componentDidMount = () => {
    window.addEventListener("resize", this._handleResize);
    this._getMessages();
    this._getReactions();
    this._getPolls();
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this._handleResize);
  };

  _handleResize = () => {
    this._chat && this._chat.current.clearLayoutCache();
  };

  _getMessages = async () => {
    const { event } = this.props;
    if (event.noChat) return;
    FirebaseHelper.onMessage(event, this._onMessage, true);
    const messages = await FirebaseHelper.getMessages(event, true);
    this.setState({ messages, loadingMessages: false });
  };

  _getPolls = async () => {
    const { event } = this.props;
    FirebaseHelper.onPollsChanged(event, this._onPollsChanged);
    const polls = await FirebaseHelper.getPolls(event);
    this.setState({ polls });
  };

  _getReactions = async () => {
    const { event } = this.props;
    const reactions = await FirebaseHelper.getReactions(event);
    this.setState({ reactions, loadingReactions: false });
    FirebaseHelper.onReaction(event, this._onReaction);
  };

  _onReaction = (snapshot) => {
    const reactions = snapshot.val() || {};
    this.setState({ reactions });
  };

  _onMessage = (snapshot) => {
    const messages = Object.values(snapshot.val() || {});
    this.setState({ messages });
  };

  _onPollsChanged = (snapshot) => {
    const polls = snapshot.val() || {};
    this.setState({ polls });
  };

  render() {
    const { event } = this.props;
    const { loadingMessages, messages, polls, reactions } = this.state;
    return (
      <div className={styles.largeChat}>
        <Chat
          ref={this._chat}
          viewMode
          showTopBar
          polls={polls}
          reactions={reactions}
          messages={messages}
          loadingMessages={loadingMessages}
          event={event}
        />
      </div>
    );
  }
}

export default LargeChat;
