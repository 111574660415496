import React from "react";
import styles from "./BarChart.module.scss";

class BarChart extends React.Component {
  render() {
    const { data } = this.props;
    let largest = 0;
    data.forEach((d) => {
      if ((d.count || 0) > largest) {
        largest = d.count;
      }
    });
    return (
      <div className={styles.barChart}>
        <div
          className={`${styles.titles} ${
            this.props.viewMode ? styles.large : ""
          }`}
        >
          {data.map((d) => {
            return (
              <div className={styles.dataRow}>
                <p>{d.title}</p>
              </div>
            );
          })}
        </div>
        <div
          className={`${styles.values} ${
            this.props.viewMode ? styles.large : ""
          }`}
        >
          {data.map((d) => {
            const percent =
              largest !== 0 ? Math.round(((d.count || 0) / largest) * 100) : 0;
            return (
              <div className={styles.dataRow}>
                <div className={styles.barContainer}>
                  <div className={styles.bar} style={{ width: `${percent}%` }}>
                    <p>{d.count || 0}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default BarChart;
