import React from "react";
import TopBar from "../../components/TopBar/TopBar";
import ArtistInfo from "../../components/ArtistInfo/ArtistInfo";
import EventTabs from "./EventTabs";
import Chat from "../../components/Chat/Chat";
import ReactionCounter from "../../components/ReactionCounter/ReactionCounter";
import { MdArrowBack, MdChat } from "react-icons/md";
import FirebaseHelper from "../../FirebaseHelper";
import { withRouter } from "react-router-dom";
import { BarLoader, PropagateLoader, ScaleLoader } from "react-spinners";
import moment from "moment";
import { initPayment } from "../../PaymentService";
import queryString from "query-string";
import _, { cloneDeep } from "lodash";
import "./Event.scss";
import Footer from "./Footer";
import Admin from "../Admin/NewAdmin";
import styles from "./Event.module.scss";
import LocalizationManager from "../../LocalizationManager";
import Axios from "axios";

const MAX_SESSIONS = 3;
class Event extends React.PureComponent {
  constructor(props) {
    super(props);
    this.uid = "";
    this.userChanged = false;
    this._mobileChat = React.createRef();
    this.state = {
      width: window.innerWidth,
      passcode: "",
      email: "",
      paymentData: null,
      loadingPaymentMethods: false,
      orderMode: false,
      loggedIn: !!this.props.admin,
      alert: null,
      messages: [],
      polls: {},
      event: {},
      ticketData: {},
      registrationData: {
        name: "",
        title: "",
        company: "",
        email: "",
        phone: "",
        custom1: "",
        in_person: "0",
        // checkboxData: {},
        // customCheckboxValue: "",
        termsAgreed: false,
      },
      amountSelection: false,
      reactions: {
        heart: 0,
        fire: 0,
        clap: 0,
      },
      loadingEvent: true,
      loadingMessages: true,
      loadingReactions: true,
      checkingPasscode: false,
      checkingInitialPasscode: false,
      pollAnswer: null,
      soldOut: false,
      registrations: [],
    };
  }

  componentDidMount = () => {
    window.addEventListener("resize", this._handleResize);
    this._getData();
    this.checkSoldOut();
    if (!this.props.admin) {
      FirebaseHelper.setOnAuthChangeListener((user) => {
        if (user) {
          this.userChanged = false;
          if (
            (this.uid == null && !this.state.checkingPasscode) ||
            (this.uid && user.uid !== this.uid)
          ) {
            this.userChanged = true;
          }
          this.uid = user.uid;
          const event = this.props.event;
          FirebaseHelper.onSessionsChanged(
            event,
            user.uid,
            this._onSessionsChanged
          );
          this.setState({
            loggedIn: true,
            checkingPasscode: false,
          });
        } else {
          const event = this.props.event;
          FirebaseHelper.removeSessionListener(
            event,
            this.uid,
            this._onSessionsChanged
          );
          this.uid = null;
          this.setState({ loggedIn: false, admin: false });
        }
      });
    }
  };

  checkSoldOut = () => {
    if (!this.props.event.ticket_limit) return;
    FirebaseHelper.getOrders(this.props.event).then((unfilteredOrders) => {
      const orders = (unfilteredOrders || []).filter((o) => !!o.completed);
      let ticketAmount = 0;
      orders.forEach((o) => {
        let amount = 0;
        if (Array.isArray(o.ticketData)) {
          amount = o.ticketData?.reduce((prev, td) => {
            if (
              (td.events || []).findIndex(
                (e) => e?.key == this.props.event?.key
              ) != -1
            ) {
              prev += Number(td.quantity);
            }
            return prev;
          }, 0);
        } else {
          if (o.ticketData?.price == undefined) {
            Object.values(o.ticketData || {}).forEach((ticket) => {
              if (
                (ticket.events || []).findIndex(
                  (e) => e?.key == this.props.event?.key
                ) != -1
              ) {
                amount += Number(ticket.quantity || "0");
              }
            });
          } else {
            if (
              (o.ticketData?.events || []).findIndex(
                (e) => e?.key == this.props.event?.key
              ) != -1
            ) {
              amount = Number(o.ticketData?.quantity || "0");
            }
          }
        }
        ticketAmount += amount;
      });
      let soldOut = false;
      const limit = Number(this.props.event.ticket_limit || 0);
      if (ticketAmount >= limit) {
        soldOut = true;
      }
      this.setState({ soldOut });
    });
  };

  _onSessionsChanged = (data) => {
    if (this.userChanged) {
      this.userChanged = false;
      return;
    }
    if (data.exists()) {
      const sessions = Object.values(data.val());
      if ((sessions || []).length === 0) {
        this._showTooManyDevicesAlert();
        this._logout();
      } else {
        const session = sessions[0];
        if (session.admin === true) {
          this.setState({ admin: true });
        }
      }
    } else {
      this._showTooManyDevicesAlert();
      this._logout();
    }
  };

  _logout = () => {
    // FirebaseHelper.removeSession(this.props.event, this.uid);
    FirebaseHelper.logOut();
  };

  _showTooManyDevicesAlert = () => {
    this.setState({
      alert: LocalizationManager.localize(
        "Sinut kirjattiin ulos. Tämä voi johtua tapahtuman päättymisestä, tapahtumasivun vaihdosta tai siitä, että käyttämääsi pääsykoodia käytetään liian useassa laitteessa.",
        this.props.event
      ),
    });
  };

  _getData = async () => {
    const params = queryString.parse(this.props.location.search);
    const passcode = this.props.admin ? null : params?.code || "";
    this.setState({ passcode, checkingInitialPasscode: !!passcode }, () => {
      if (passcode) {
        this.checkPasscode();
      }
      this._getMessages();
      this._getReactions();
      this._getPolls();
      this._getRegistrations();
      FirebaseHelper.onShownPollChange(
        this.props.event,
        this._onShownPollChange
      );
    });
  };

  _getReactions = async () => {
    const { event } = this.props;
    const reactions = await FirebaseHelper.getReactions(event);
    this.setState({ reactions, loadingReactions: false });
    FirebaseHelper.onReaction(event, this._onReaction);
  };

  _getRegistrations = async () => {
    const { event } = this.props;
    if (!event.use_registration) return;
    const registrations = await FirebaseHelper.getRegistrations(event);
    this.setState({ registrations });
    FirebaseHelper.onRegistration(event, this._onRegistration);
  };

  _onRegistration = (snapshot) => {
    const registrations = Object.values(snapshot.val() || {});
    this.setState({ registrations });
  };

  _onReaction = (snapshot) => {
    const reactions = snapshot.val() || {};
    this.setState({ reactions });
  };

  _onShownPollChange = (snapshot) => {
    const { polls } = this.state;
    const shown_poll = snapshot.val();
    let shownPoll = null;
    if (shown_poll !== undefined && polls) {
      shownPoll = polls[shown_poll];
    }
    this.setState({ shownPoll });
  };

  componentWillUnmount = () => {
    const { event } = this.props;
    window.removeEventListener("resize", this._handleResize);
    FirebaseHelper.removeSessionListener(
      event,
      this.uid,
      this._onSessionsChanged
    );
  };

  _getMessages = async () => {
    const { event } = this.props;
    if (event.noChat) return;
    FirebaseHelper.onMessage(event, this._onMessage, true);
    const messages = await FirebaseHelper.getMessages(event, true);
    this.setState({ messages, loadingMessages: false });
  };

  _getPolls = async () => {
    const { event } = this.props;
    FirebaseHelper.onPollsChanged(event, this._onPollsChanged);
    const polls = await FirebaseHelper.getPolls(event);
    this.setState({ polls });
  };

  _onMessage = (snapshot) => {
    const messages = Object.values(snapshot.val() || {});
    this.setState({ messages });
  };

  _onPollsChanged = (snapshot) => {
    const polls = snapshot.val() || {};
    this.setState({ polls });
  };

  scrollToSupport = () => {
    if (!this._showSupportTitle.current) {
      return;
    }
    window.scrollTo({
      top: this._showSupportTitle.current.offsetTop,
      left: 0,
      behavior: "smooth",
    });
    setTimeout(() => {
      this._showSupport.current && this._showSupport.current.flash();
    }, 500);
  };

  _handleResize = () => {
    let showChat = this.state.showChat;
    if (this._mobileChat.current) {
      this._mobileChat.current.clearLayoutCache();
    }
    if (this.state.width < 1200 && window.innerWidth >= 1200) {
      showChat = false;
    }
    this.setState({
      width: window.innerWidth,
      showChat,
    });
  };

  isAdmin = () => {
    const params = queryString.parse(this.props.location.search);
    const admin = params?.admin == "true";
    return admin;
  };

  _renderStream = () => {
    const { loggedIn } = this.state;
    const { event, admin } = this.props;
    return (
      <div className="stream-container">
        <div className="row">
          <div className="stream">
            {event.stream_url &&
              !((event.require_ticket || this.isAdmin()) && !loggedIn) && (
                <iframe
                  title="VES Stream"
                  src={event.stream_url}
                  mozallowfullscreen="true"
                  webkitallowfullscreen="true"
                  allowFullScreen={true}
                  allow="fullscreen; autoplay"
                  frameBorder="0"
                />
              )}
          </div>
          {!event.noChat && (
            <div className="chat">
              {this.state.width >= 1200 ? (
                <Chat
                  admin={admin}
                  polls={this.state.polls}
                  scrollToSupport={this.scrollToSupport}
                  messages={this.state.messages}
                  loadingMessages={this.state.loadingMessages}
                  event={event}
                />
              ) : null}
            </div>
          )}
        </div>
      </div>
    );
  };

  _renderTopContent = () => {
    const { event, admin } = this.props;
    return (
      <React.Fragment>
        {!admin && (
          <TopBar
            event={event}
            noRequests={this.props.noRequests}
            size={event.logo_size || 40}
            logo={event.logo_url}
            // christmasMode={event.key === "reilukauppa"}
            resetSubEvent={event.sub_events && this._backToEventSelection}
          />
        )}
        {this._renderStream()}
      </React.Fragment>
    );
  };

  _toggleChat = () => {
    this.setState({ showChat: !this.state.showChat });
  };

  _checkCodeValidity = (passcodeData) => {
    let valid = true;
    const now = moment();
    if (passcodeData.valid_from && passcodeData.valid_to) {
      const momentFrom = moment(passcodeData.valid_from, "YYYY-MM-DD");
      const momentTo = moment(passcodeData.valid_to, "YYYY-MM-DD");
      valid = now.isBetween(momentFrom, momentTo, "date", "()");
    } else if (passcodeData.valid_from) {
      const momentFrom = moment(passcodeData.valid_from, "YYYY-MM-DD");
      valid = now.isAfter(momentFrom, "date");
    } else if (passcodeData.valid_to) {
      const momentTo = moment(passcodeData.valid_to, "YYYY-MM-DD");
      valid = now.isBefore(momentTo, "date");
    }
    return valid;
  };

  checkPasscode = () => {
    if (this.eventNotStarted()) {
      this.setState({
        checkingPasscode: false,
        checkingInitialPasscode: false,
      });
      return;
    }
    this.setState(
      { checkingPasscode: true },
      _.debounce(() => {
        const { passcode } = this.state;
        FirebaseHelper.checkPasscode(passcode, this.props.event)
          .then((passcodeData) => {
            const valid = this._checkCodeValidity(passcodeData);
            if (!valid) {
              this.setState({
                checkingPasscode: false,
                checkingInitialPasscode: false,
                alert: LocalizationManager.localize(
                  "Antamasi pääsykoodi ei ole käytettävissä tänään. Ole hyvä ja tarkista pääsykoodi.",
                  this.props.event
                ),
              });
              return;
            }
            if (this.isAdmin() && !passcodeData.admin) {
              this.setState({
                checkingPasscode: false,
                checkingInitialPasscode: false,
                alert: LocalizationManager.localize(
                  "Antamasi pääsykoodi ei ole käytettävissä tapahtuman hallinnoimiseen. Ole hyvä ja tarkista pääsykoodi.",
                  this.props.event
                ),
              });
              return;
            }
            this.setState({
              loggedIn: true,
              admin: passcodeData.admin || false,
              checkPasscode: false,
              checkingInitialPasscode: false,
            });
            this.login(passcodeData);
          })
          .catch(() => {
            this.setState({
              checkingPasscode: false,
              checkingInitialPasscode: false,
              alert: LocalizationManager.localize(
                "Antamasi pääsykoodi ei ole käytettävissä. Ole hyvä ja tarkista pääsykoodi.",
                this.props.event
              ),
            });
          });
      }, 1000)
    );
  };

  login = (passcodeData) => {
    const passcode = passcodeData.passcode;
    FirebaseHelper.getSessions(this.props.event, passcode).then(
      (oldSessions) => {
        let maxSessionAmount = Number(
          this.props.event.sessions_per_ticket || MAX_SESSIONS
        );
        if (passcodeData.amount) {
          maxSessionAmount =
            Number(passcodeData.amount) *
            Number(this.props.event.sessions_per_ticket || MAX_SESSIONS);
          if (passcodeData.sessions_per_ticket) {
            maxSessionAmount =
              Number(passcodeData.amount) *
              Number(passcodeData.sessions_per_ticket);
          }
        }
        if (
          oldSessions.length >= maxSessionAmount &&
          !this.props.event.single_code &&
          passcodeData.amount != "-1"
        ) {
          FirebaseHelper.removeOneSession(this.props.event, passcode);
        }
        FirebaseHelper.logIn().then((data) => {
          FirebaseHelper.setSession(
            this.props.event,
            passcode,
            data.user.uid,
            passcodeData.admin || false
          );
        });
      }
    );
  };

  onPasscodeChanged = (e) => {
    this.setState({
      passcode: e.target.value || "",
    });
  };

  onEmailChanged = (e) => {
    this.setState({ email: (e.target.value || "").trim() });
  };

  _loadPaymentData = () => {
    const { email, ticketData } = this.state;
    const { event } = this.props;
    this.setState({ loadingPaymentMethods: true }, async () => {
      let paymentData = await initPayment(
        event,
        email,
        ticketData,
        (event.language || "fi").toUpperCase()
      );
      let tickets = _.cloneDeep(ticketData);
      let finalTickets = {};
      Object.keys(tickets).forEach((key) => {
        const ticketObject = tickets[key];
        if (ticketObject.events) {
          ticketObject.events = ticketObject.events.map((e) => {
            const fullEvent = event.sub_events.find((se) => se.key === e);
            return { key: fullEvent.key, name: fullEvent.name };
          });
        }
        if (ticketObject.quantity) {
          finalTickets[key] = ticketObject;
        }
      });
      let transactionData = null;
      if (paymentData) {
        transactionData = {
          id: paymentData.transactionId,
          email,
          ticketData: finalTickets,
          event_name: event.ticket_name || event.name,
          event_date: event.date,
          event_time: event.time,
          language: event.language || "fi",
          event_customer:
            event.customer ||
            (event.language == "en" ? "the customer" : "asiakas"),
          event_max_sessions: event.sessions_per_ticket || MAX_SESSIONS,
          timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
        };
        if (event.date_range) {
          transactionData.date_range = event.date_range;
        }
      }
      if (transactionData) {
        FirebaseHelper.createTransaction(transactionData, event)
          .then(() => {
            if (!paymentData) {
              paymentData = {
                providers: [],
                terms: "",
              };
            }
            this.setState({ paymentData, loadingPaymentMethods: false });
          })
          .catch((err) => {
            console.error(err);
            alert(
              LocalizationManager.localize(
                "Maksutietojen haku epäonnistui.",
                this.props.event
              )
            );
            paymentData = {
              providers: [],
              terms: "",
            };
            this.setState({ paymentData, loadingPaymentMethods: false });
          });
      } else {
        if (!paymentData) {
          paymentData = {
            providers: [],
            terms: "",
          };
        }
        this.setState({ paymentData, loadingPaymentMethods: false });
      }
    });
  };

  setOrderMode = () => {
    window.scrollTo(0, 0);
    this.setState({ orderMode: true, amountSelection: true });
  };

  closeOrderMode = () =>
    this.setState({
      orderMode: false,
      registrationComplete: false,
      paymentData: null,
    });

  _parameterToInput = (param, i) => (
    <input
      key={param.name + "_" + i}
      type="hidden"
      name={param.name}
      value={param.value}
    />
  );

  _renderPaymentMethods = () => {
    const { paymentData, loadingPaymentMethods } = this.state;
    if (loadingPaymentMethods) {
      return (
        <div className="loadingPaymentMethods">
          <ScaleLoader color="white" />
          <h2>
            {LocalizationManager.localize(
              "Ladataan maksutapoja...",
              this.props.event
            )}
          </h2>
        </div>
      );
    }
    return (
      <div className="payment-container">
        <h2>
          {LocalizationManager.localize(
            "Siirry maksamaan valitsemalla maksutapa alta:",
            this.props.event
          )}
        </h2>
        <div className="payment-methods">
          {paymentData &&
            paymentData.providers.map((provider, i) => {
              return (
                <form
                  target="_tab"
                  title={provider.name}
                  method="POST"
                  key={provider.id + "_" + i}
                  action={provider.url}
                  onSubmit={(e) => {
                    e.target.submit();
                    setTimeout(() => {
                      this.closeOrderMode();
                    }, 1000);
                  }}
                >
                  {provider.parameters.map((obj) =>
                    this._parameterToInput(obj, i)
                  )}
                  <button className="provider">
                    <img alt={provider.name} src={provider.svg} />
                  </button>
                </form>
              );
            })}
        </div>
      </div>
    );
  };

  _isValidEmail = (email) => {
    const re = /^\S+@\S+\.\S+$/;
    return re.test(email);
  };

  _setTicketAmount = (e, key, ticket) => {
    let value = e.currentTarget.value;
    if (value === "0") value = "1";
    if (value.endsWith(".")) return;
    if (value !== "" && !Number.isFinite(Number(value))) return;
    this.setState({
      ticketData: {
        ...this.state.ticketData,
        [key]: { quantity: value, ...ticket },
      },
    });
  };

  _moveToPayment = () => {
    if (this.state.soldOut) return;
    this.setState({ amountSelection: false }, () => {
      this._loadPaymentData();
    });
  };

  _getTotalPrice = () => {
    const { ticketData } = this.state;
    let total = 0;
    Object.keys(ticketData).forEach((key) => {
      total += Number(ticketData[key].quantity) * Number(ticketData[key].price);
    });
    return total;
  };

  _getTotalQuantity = () => {
    const { ticketData } = this.state;
    let total = 0;
    Object.keys(ticketData).forEach((key) => {
      total += Number(ticketData[key].quantity);
    });
    return total;
  };

  _getTicketValidityString = (ticket) => {
    if (ticket.valid_from && ticket.valid_to) {
      return (
        <h3>{`Voimassa ${moment(ticket.valid_from, "YYYY-MM-DD").format(
          "DD.MM.YYYY"
        )} - ${moment(ticket.valid_to, "YYYY-MM-DD").format(
          "DD.MM.YYYY"
        )}.`}</h3>
      );
    } else if (ticket.valid_from) {
      return (
        <h3>{`Voimassa ${moment(ticket.valid_from, "YYYY-MM-DD").format(
          "DD.MM.YYYY"
        )} alkaen.`}</h3>
      );
    } else if (ticket.valid_to) {
      return (
        <h3>{`Voimassa ${moment(ticket.valid_to, "YYYY-MM-DD").format(
          "DD.MM.YYYY"
        )} saakka.`}</h3>
      );
    }
    return null;
  };

  _backToEventSelection = () => {
    const formattedKey = this.props.event.key.split("_")[0];
    this.props.history.push(`/${formattedKey}`);
    this.props.resetSubEvent();
  };

  _getFilteredTickets = () => {
    const { event } = this.props;
    if (!event.sub_events) {
      return event.tickets.map((t, i) => ({ ...t, key: i }));
    }
    return event.tickets
      .map((t, i) => ({ ...t, key: i }))
      .filter((t) => {
        return !t.events || (t.events || []).indexOf(event.key) !== -1;
      });
  };

  _renderStartOrder = () => {
    const { event } = this.props;
    const { email } = this.state;
    if (event.use_registration) {
      return (
        <>
          <div className="order-new">
            <button
              className="large"
              style={
                event.customLayout?.highlightColor && {
                  backgroundColor: event.customLayout?.highlightColor,
                  color: event.customLayout?.darkTitles ? "#1E1E1E" : undefined,
                }
              }
              onClick={this.setOrderMode}
            >
              {LocalizationManager.localize(
                "Ilmoittaudu tilaisuuteen",
                this.props.event
              )}
            </button>
          </div>
        </>
      );
    }
    return (
      <>
        {" "}
        <hr />
        <div className="order-new">
          <h2>
            {event.is_live
              ? LocalizationManager.localize(
                  "Tilaa yksi tai useampi virtuaalinen lippu sähköpostiisi tästä!",
                  this.props.event
                )
              : LocalizationManager.localize(
                  "Ei vielä pääsykoodia? Tilaa yksi tai useampi virtuaalinen lippu sähköpostiisi tästä!",
                  this.props.event
                )}
          </h2>
          <input
            className="email"
            placeholder={LocalizationManager.localize(
              "Kirjoita sähköpostiosoitteesi",
              this.props.event
            )}
            value={email}
            type="email"
            onChange={this.onEmailChanged}
            onKeyDown={(e) => {
              if (
                !email ||
                (email || "").length === 0 ||
                !this._isValidEmail(email)
              ) {
                return;
              }
              if (e.key === "Enter") {
                this.setOrderMode();
              }
            }}
          />
          {!!email && (
            <div className="email-warning">
              {LocalizationManager.localize(
                "HUOM! Varmista, että sähköpostiosoite on kirjoitettu oikein ennen kuin jatkat seuraavaan vaiheeseen.",
                this.props.event
              )}
            </div>
          )}
          <button
            disabled={
              !email || (email || "").length === 0 || !this._isValidEmail(email)
            }
            onClick={this.setOrderMode}
          >
            {LocalizationManager.localize(`Siirry tilaamaan`, this.props.event)}
          </button>
        </div>
      </>
    );
  };

  _registrationDataIsInvalid = () => {
    let invalid = false;
    Object.keys(this.state.registrationData).forEach((key) => {
      if (
        !this.state.registrationData[key] &&
        key != "phone" &&
        key != "custom1" &&
        key != "in_person" &&
        key != "customCheckboxValue"
      ) {
        invalid = true;
      }
      if (
        key == "email" &&
        !this._isValidEmail(this.state.registrationData[key])
      ) {
        invalid = true;
      }
    });
    // if (
    //   Object.values(this.state.registrationData.checkboxData).filter(
    //     (v) => v == true
    //   ).length == 0
    // ) {
    //   invalid = true;
    // }
    return invalid;
  };

  _register = () => {
    this.setState({ loadingRegistration: true }, async () => {
      const { event } = this.props;
      const { registrationData } = this.state;
      const {
        name,
        title,
        company,
        email,
        phone,
        custom1,
        checkboxData,
        customCheckboxValue,
        in_person,
      } = registrationData;
      const data = new FormData();
      data.append("name", name);
      data.append("title", title);
      data.append("company", company);
      data.append("email", email);
      data.append("phone", phone);
      data.append("custom1", custom1);
      data.append("in_person", in_person);
      // data.append(
      //   "checkboxData",
      //   Object.keys(checkboxData).filter((key) => !!checkboxData[key])
      // );
      // data.append("customCheckboxValue", customCheckboxValue);
      data.append("event_name", event.ticket_name || event.name);
      data.append("event_key", event.key.split("_")[0]);
      data.append("event_date", event.date);
      data.append("event_time", event.time);
      data.append("language", event.language || "fi");
      data.append(
        "event_customer",
        event.customer || (event.language == "en" ? "the customer" : "asiakas")
      );
      data.append(
        "event_max_sessions",
        event.sessions_per_ticket || MAX_SESSIONS
      );
      try {
        const res = await Axios.post(
          process.env.NODE_ENV === "development"
            ? "http://localhost:8000/server/registrationMailSender.php"
            : process.env.PUBLIC_URL + "/registrationMailSender.php",
          data
        );
        this.setState({
          registrationComplete: true,
          loadingRegistration: false,
          registrationData: {
            name: "",
            title: "",
            company: "",
            email: "",
            phone: "",
            custom1: "",
            in_person: "0",
            // checkboxData: {},
            customCheckboxValue: "",
            termsAgreed: false,
          },
        });
      } catch (e) {
        alert(
          LocalizationManager.localize("Ilmoittautuminen epäonnistui.", event)
        );
        this.setState({
          registrationComplete: true,
          loadingRegistration: false,
          registrationData: {
            name: "",
            title: "",
            company: "",
            email: "",
            phone: "",
            custom1: "",
            in_person: "0",
            // checkboxData: {},
            customCheckboxValue: "",
            termsAgreed: false,
          },
        });
      }
    });
  };

  _setRegistrationValue = (e) => {
    let registrationData = cloneDeep(this.state.registrationData);
    if (e.target.type == "radio") {
      registrationData = {
        ...registrationData,
        [e.target.name]: e.target.value,
      };
    } else if (e.target.type == "checkbox") {
      if (Number.isFinite(Number(e.target.name))) {
        registrationData = {
          ...registrationData,
          checkboxData: {
            ...registrationData.checkboxData,
            [e.target.name]: e.target.checked,
          },
        };
      } else {
        registrationData = {
          ...registrationData,
          [e.target.name]: e.target.checked,
        };
      }
    } else {
      registrationData = {
        ...registrationData,
        [e.target.name]: e.target.value,
      };
    }
    this.setState({ registrationData });
  };

  _renderIntroText = () => {
    const { event } = this.props;
    if (event.key != "reilukauppa") return null;
    const header = "Tervetuloa Reilun kaupan joulupuurolle!";
    const smallHeader = "Tervetuloa Reilun kaupan joulupuurolle!";
    const greetings = "Parhain terveisin,\nReilu kauppa ry:n tiimi";
    return (
      <div className="intro-text">
        <h1 className="large">{header}</h1>
        <h1 className="small">{smallHeader}</h1>
        <div className="intro-text-content">
          <h2>Reilun kaupan joulupuuro</h2>
          <h2 className="sub-header">To 1.12.2022 klo 9–10.30</h2>
          <h2 className="sub-header">
            Verkossa ja livenä @ St. George Wintergarden
          </h2>
          <p>
            Hei Reilun kaupan kumppani, tervetuloa Reilun kaupan joulupuurolle!{" "}
          </p>
          <p>
            Järjestämme tänä vuonna Reilun kaupan perinteisen
            joulupuurotilaisuuden kumppaneillemme hybridinä. Olet tervetullut
            osallistumaan tilaisuuteen paikan päällä ainutlaatuisessa{" "}
            <a
              href="https://www.stgeorgehelsinki.com/fi/kokoustilat-ja-juhlatilat-helsingissa/wintergarden"
              target="_blank"
            >
              St. Georgen Wintergardenissa
            </a>{" "}
            (Yrjönkatu 13, 00120 Helsinki) tai virtuaalisesti tapahtumasivulla!
          </p>
          <p>
            Hienoa, että olette mukana tekemässä maailmasta ja kaupankäynnistä
            reilumpaa. Yhdessä voimme tehdä vaikuttavia vastuullisuustekoja.
          </p>
          <p>Toivottavasti tapaamme joko kasvotusten tai etänä!</p>
          <p>{greetings}</p>
          <h2>OHJELMA</h2>
          <p>Aamiainen puuroineen tarjolla 8.30 alkaen, ohjelma alkaa 9.00:</p>
          <ul>
            <li>
              Lisävoimin vuoteen 2023: Toiminnanjohtajan ja tiimien tervehdykset
            </li>
            <li>
              Bränditutkimus 2022, Reilun kaupan viikko ja muita nostoja
              kuluneelta vuodelta
            </li>
            <li>
              Mikä tuote, mikä maa ja mitkä riskit? Miten Reilu kauppa tukee
              yrityskumppaneitaan olennaisten vastuullisuusriskien
              tunnistamisessa?
            </li>
            <li>
              Yhä paremmin, yhä enemmän, yhä vastuullisemmin – reilusti yhdessä
              vuonna 2023
            </li>
            <li>Aikaa kysymyksille ja keskusteluun</li>
          </ul>
          <p style={{ marginTop: 32 }}>
            Liity mukaan, paikan päällä tai etänä!
          </p>
        </div>
      </div>
    );
  };

  _renderOrderSheet = () => {
    const {
      email,
      amountSelection,
      ticketData,
      registrationData,
      registrationComplete,
      loadingRegistration,
    } = this.state;
    const { event } = this.props;
    if (event.use_registration) {
      if (registrationComplete) {
        return (
          <div className="order">
            <h1>
              {LocalizationManager.localize(
                "Kiitos ilmoittautumisesta! 🎉",
                event
              )}
            </h1>
            <h2>
              {LocalizationManager.localize(
                "Tapahtumalinkki virtuaaliseen osallistumiseen on lähetetty antamaasi sähköpostiosoitteeseen.",
                event
              )}
            </h2>
            <button
              style={
                event.customLayout?.highlightColor && {
                  backgroundColor: event.customLayout?.highlightColor,
                }
              }
              className="large"
              onClick={this.closeOrderMode}
            >
              {LocalizationManager.localize(
                "Takaisin etusivulle",
                this.props.event
              )}
            </button>
          </div>
        );
      }
      const inPersonFull =
        Number(this.props.event.in_person_limit || 0) > 0 &&
        (this.state.registrations || []).filter((r) => r.in_person == 1)
          .length >= Number(this.props.event.in_person_limit);
      return (
        <div className="order">
          <h1>
            {LocalizationManager.localize(
              "Ilmoittaudu Reilun kaupan joulupuurolle!",
              this.props.event
            )}
          </h1>
          <h2 style={{ maxWidth: 1000 }}>
            {LocalizationManager.localize(
              "Varmistathan osallistumisesi mahdollisimman pian, mukaan paikan päälle mahtuu 50 ensimmäiseksi ilmoittautunutta. Kaikki muut toivotamme lämpimästi tervetulleiksi mukaan virtuaalisesti. Tapahtumalinkki virtuaaliseen osallistumiseen lähetetään kaikille sähköpostiosoitteeseen.",
              event
            )}
          </h2>
          <div className="form">
            <p>
              {LocalizationManager.localize("Osallistun *", this.props.event)}
            </p>
            <div className="radio">
              <div>
                <input
                  type="radio"
                  id="in_person_1"
                  name="in_person"
                  value="0"
                  checked={registrationData.in_person == 0}
                  onChange={this._setRegistrationValue}
                />
                <label for="in_person_1">Virtuaalisesti</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="in_person_2"
                  name="in_person"
                  value="1"
                  checked={registrationData.in_person == 1}
                  onChange={this._setRegistrationValue}
                  disabled={inPersonFull}
                />
                <label for="in_person_2">
                  Paikan päällä {inPersonFull ? "(Paikat täynnä)" : ""}
                </label>
              </div>
            </div>
            <p>{LocalizationManager.localize("Nimi *", this.props.event)}</p>
            <input
              value={registrationData.name || ""}
              onChange={this._setRegistrationValue}
              name="name"
            />
            <p>{LocalizationManager.localize("Titteli *", this.props.event)}</p>
            <input
              value={registrationData.title || ""}
              onChange={this._setRegistrationValue}
              name="title"
            />
            <p>
              {LocalizationManager.localize("Organisaatio *", this.props.event)}
            </p>
            <input
              value={registrationData.company || ""}
              onChange={this._setRegistrationValue}
              name="company"
            />
            <p>
              {LocalizationManager.localize(
                "Sähköpostiosoite *",
                this.props.event
              )}
            </p>
            <input
              value={registrationData.email || ""}
              onChange={this._setRegistrationValue}
              name="email"
              type="email"
            />
            <p>
              {LocalizationManager.localize("Puhelinnumero", this.props.event)}
            </p>
            <input
              value={registrationData.phone || ""}
              onChange={this._setRegistrationValue}
              name="phone"
              type="tel"
            />
            {/* <p>
              {LocalizationManager.localize(
                "Olen kiinnostunut (valitse väh. yksi) *:",
                this.props.event
              )}
            </p> */}
            {/* <div className="checkboxes">
              <div>
                <input
                  name="1"
                  onChange={this._setRegistrationValue}
                  checked={registrationData.checkboxData["1"]}
                  type="checkbox"
                />
                <p>Valmistamaan Reilun kaupan tuotteita</p>
              </div>
              <div>
                <input
                  name="2"
                  onChange={this._setRegistrationValue}
                  checked={registrationData.checkboxData["2"]}
                  type="checkbox"
                />
                <p>Maahantuomaan uusia Reilun kaupan tuotteita</p>
              </div>
              <div>
                <input
                  name="3"
                  onChange={this._setRegistrationValue}
                  checked={registrationData.checkboxData["3"]}
                  type="checkbox"
                />
                <p>
                  Myymään Reilun kaupan tuotteita
                  myymälässä/kahvilassa/ravintolassa
                </p>
              </div>
              <div>
                <input
                  name="4"
                  onChange={this._setRegistrationValue}
                  checked={registrationData.checkboxData["4"]}
                  type="checkbox"
                />
                <p>
                  Olen kiinnostunut eri mahdollisuuksista Reilun kaupan kanssa
                </p>
              </div>
              <div>
                <input
                  name="5"
                  onChange={this._setRegistrationValue}
                  checked={registrationData.checkboxData["5"]}
                  type="checkbox"
                />
                <p>
                  Yritystoimintamme ihmisoikeusriskien kartoittamisesta ja
                  vastuullisuuden parantamisesta Reilun kaupan kanssa
                </p>
              </div>
              <div>
                <input
                  name="6"
                  onChange={this._setRegistrationValue}
                  checked={registrationData.checkboxData["6"]}
                  type="checkbox"
                />
                <p>Muu, mikä?</p>
              </div>
              <input
                value={registrationData.customCheckboxValue || ""}
                onChange={this._setRegistrationValue}
                disabled={!registrationData.checkboxData["6"]}
                name="customCheckboxValue"
              />
            </div> */}
            <p>
              {LocalizationManager.localize(
                "Mitä haluaisit tietää Reilun kaupan toiminnasta Suomessa tai kansainvälisesti?",
                this.props.event
              )}
            </p>
            <input
              value={registrationData.custom1 || ""}
              onChange={this._setRegistrationValue}
              name="custom1"
            />
            <div className="row">
              <input
                type="checkbox"
                name="termsAgreed"
                onChange={this._setRegistrationValue}
                checked={registrationData.termsAgreed}
              />
              <p>
                Olen lukenut Reilun kaupan{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://reilukauppa.fi/tietosuojaseloste/"
                >
                  tietosuojaselosteen
                </a>{" "}
                ja hyväksyn sen ehdot. *
              </p>
            </div>
          </div>
          {loadingRegistration ? (
            <button
              style={
                event.customLayout?.highlightColor && {
                  backgroundColor: event.customLayout?.highlightColor,
                }
              }
              className="large"
              disabled={true}
            >
              <BarLoader loading={true} color="white" margin={10} />
            </button>
          ) : (
            <button
              style={
                event.customLayout?.highlightColor && {
                  backgroundColor: event.customLayout?.highlightColor,
                }
              }
              className="large"
              disabled={this._registrationDataIsInvalid()}
              onClick={this._register}
            >
              {LocalizationManager.localize("Ilmoittaudu", this.props.event)}
            </button>
          )}
          <button className="large cancel" onClick={this.closeOrderMode}>
            {LocalizationManager.localize("Takaisin", this.props.event)}
          </button>
        </div>
      );
    }
    return amountSelection ? (
      <div className="order">
        <h1>{LocalizationManager.localize("Tee tilaus", this.props.event)}</h1>
        <h2>
          {/* {this.props.event.language == "en"
            ? `Please select your tickets below. You can watch the event simultaneously with ${
                event.sessions_per_ticket || MAX_SESSIONS
              } device(s).`
            : `Valitse haluamasi liput alta. Yhdellä lipulla tapahtumaa voi katsella yhtäaikaisesti ${
                event.sessions_per_ticket || MAX_SESSIONS
              } laitteella.`} */}
          {this.props.event.language == "en"
            ? `Please select your tickets below.`
            : `Valitse haluamasi liput alta.`}
        </h2>
        {event.custom_order_description && (
          <h2>{event.custom_order_description}</h2>
        )}
        <div className="tickets-container">
          {Object.keys(this._getFilteredTickets()).map((key) => {
            const ticket = this._getFilteredTickets()[key];
            return (
              <div className="ticket-container">
                <div className="ticket">
                  <div className="price">
                    <h2>{ticket.name}</h2>
                    <h1>{`${ticket.price} €`}</h1>
                    {this._getTicketValidityString(ticket)}
                  </div>
                  <div className="amount">
                    <input
                      onChange={(e) => this._setTicketAmount(e, key, ticket)}
                      value={ticketData[key]?.quantity}
                      placeholder={"0"}
                    />
                    <p>
                      {LocalizationManager.localize("kpl", this.props.event)}
                    </p>
                  </div>
                  {this.state.soldOut && (
                    <div className="sold-out">
                      {LocalizationManager.localize(
                        "Loppuunmyyty",
                        this.props.event
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <h2>
          {this.props.event.language == "en"
            ? `Total price: ${this._getTotalPrice()} €`
            : `Hinta yhteensä: ${this._getTotalPrice()} €`}
        </h2>
        <div className="email-warning">
          {this.props.event.language == "en"
            ? `The receipt and passcode(s) will be sent to ${email}`
            : this.props.event.is_live
            ? `Kuitti ja pääsykoodi(t) lähetetään osoitteeseen ${email}. Tilausvahvistus toimii pääsylippuna, joka näytetään ovella tapahtumaan saapuessa.`
            : `Kuitti ja pääsykoodi(t) lähetetään osoitteeseen ${email}`}
        </div>
        <button
          disabled={this._getTotalQuantity() === 0 || this.state.soldOut}
          onClick={this._moveToPayment}
        >
          {LocalizationManager.localize("Seuraava", this.props.event)}
        </button>
        <button className="cancel" onClick={this.closeOrderMode}>
          {LocalizationManager.localize("Peruuta", this.props.event)}
        </button>
      </div>
    ) : (
      <div className="order">
        <h1>
          {this.props.event.language == "en"
            ? `Order passcode(s) to ${email}`
            : `Tilaa pääsykoodi osoitteeseen ${email}`}
        </h1>
        <h2>
          {this.props.event.language == "en"
            ? `${this._getTotalQuantity()} ticket(s), total price: ${this._getTotalPrice()} €`
            : `${this._getTotalQuantity()} lippu(a), hinta yhteensä: ${this._getTotalPrice()} €`}
        </h2>
        {this._renderPaymentMethods()}
        <button className="cancel" onClick={this.closeOrderMode}>
          {LocalizationManager.localize("Peruuta", this.props.event)}
        </button>
      </div>
    );
  };

  _renderTicketOverlay = () => {
    const { passcode, orderMode, alert, checkingPasscode } = this.state;
    const { event } = this.props;
    return (
      <div
        className={`overlay root-content ${
          event.customLayout?.darkTitles && "dark-titles"
        }`}
      >
        {event.customLayout?.ticketOverlayBackground && (
          <img
            src={event.customLayout?.ticketOverlayBackground}
            alt="Custom BG"
          />
        )}
        {event.customLayout?.registrationFormBackground && (
          <img
            src={event.customLayout?.registrationFormBackground}
            style={{ opacity: orderMode ? 1 : 0 }}
            alt="Custom BG"
          />
        )}
        <div className="content-wrapper">
          {event.sub_events && (
            <button onClick={this._backToEventSelection} className="back-btn">
              <MdArrowBack />
              {LocalizationManager.localize(
                "Takaisin tapahtuman valintaan",
                this.props.event
              )}
            </button>
          )}
          {orderMode ? (
            this._renderOrderSheet()
          ) : alert ? (
            <>
              <h1 className="small">{alert}</h1>
              <button onClick={() => this.setState({ alert: null })}>OK</button>
            </>
          ) : (
            <>
              {this._renderIntroText()}
              {!event.use_registration && (
                <h1>
                  {event.is_live
                    ? LocalizationManager.localize(
                        this.isAdmin()
                          ? "Tämä tapahtuma järjestetään livenä. Osta lippusi alta! 🎫"
                          : "Tämä tapahtuma järjestetään livenä. Osta lippusi alta! 🎫",
                        this.props.event
                      )
                    : LocalizationManager.localize(
                        this.isAdmin()
                          ? "Tarvitset pääsykoodin hallinnoidaksesi tätä tapahtumaa."
                          : "Tarvitset pääsykoodin katsoaksesi tätä tapahtumaa.",
                        this.props.event
                      )}{" "}
                  {!event.is_live && (
                    <span aria-labelledby="emoji" role="img">
                      🔒
                    </span>
                  )}
                </h1>
              )}
              {!event.use_registration && !event.is_live && (
                <div className="has-passcode">
                  <h2>
                    {LocalizationManager.localize(
                      "Onko sinulla jo koodi? Kirjoita se tähän!",
                      this.props.event
                    )}
                  </h2>
                  <input
                    placeholder={LocalizationManager.localize(
                      "Kirjoita pääsykoodi",
                      this.props.event
                    )}
                    onChange={this.onPasscodeChanged}
                    autoCapitalize="off"
                    autoCorrect="off"
                    autoComplete="off"
                    spellCheck={false}
                    value={passcode}
                    onKeyDown={(e) => {
                      if (
                        !passcode ||
                        passcode.trim().length === 0 ||
                        checkingPasscode
                      ) {
                        return;
                      }
                      if (e.key === "Enter") {
                        this.checkPasscode();
                      }
                    }}
                  />
                  {checkingPasscode ? (
                    <button
                      style={
                        event.customLayout?.highlightColor && {
                          backgroundColor: event.customLayout?.highlightColor,
                        }
                      }
                      disabled={true}
                    >
                      <BarLoader loading={true} color="white" margin={10} />
                    </button>
                  ) : (
                    <button
                      style={
                        event.customLayout?.highlightColor && {
                          backgroundColor: event.customLayout?.highlightColor,
                          color: event.customLayout?.textColor,
                        }
                      }
                      disabled={!passcode || passcode.trim().length === 0}
                      onClick={this.checkPasscode}
                    >
                      {LocalizationManager.localize(
                        "Tarkista pääsykoodi",
                        this.props.event
                      )}
                    </button>
                  )}
                </div>
              )}
              {!event.single_code &&
                !this.isAdmin() &&
                this._renderStartOrder()}
              {event.key != "reilukauppa" &&
                (this.props.event.language == "en" ? (
                  <p className="problem">
                    If you are facing issues, please read our{" "}
                    <a
                      style={
                        event.customLayout?.highlightColor && {
                          color: event.customLayout?.highlightColor,
                        }
                      }
                      href="/faq"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      frequently asked questions
                    </a>
                    .
                  </p>
                ) : (
                  <p className="problem">
                    Ongelmatapauksissa luethan{" "}
                    <a
                      style={
                        event.customLayout?.highlightColor && {
                          color: event.customLayout?.highlightColor,
                        }
                      }
                      href="/faq"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      usein kysytyt kysymykset
                    </a>
                    .
                  </p>
                ))}
              {event.key != "reilukauppa" &&
                (this.props.event.language == "en" ? (
                  <p className="problem">
                    If you can't find a solution, please contact us at{" "}
                    <a
                      style={
                        event.customLayout?.highlightColor && {
                          color: event.customLayout?.highlightColor,
                        }
                      }
                      href="mailto:support@virtualeventstudio.fi"
                    >
                      support@virtualeventstudio.fi
                    </a>
                  </p>
                ) : (
                  <p className="problem">
                    Jos ongelmaan ei löydy ratkaisua, ota yhteyttä osoitteeseen{" "}
                    <a
                      style={
                        event.customLayout?.highlightColor && {
                          color: event.customLayout?.highlightColor,
                        }
                      }
                      href="mailto:support@virtualeventstudio.fi"
                    >
                      support@virtualeventstudio.fi
                    </a>
                  </p>
                ))}
            </>
          )}
        </div>
        <Footer event={this.props.event} />
      </div>
    );
  };

  _renderNoEventOverlay = () => {
    return (
      <div className="overlay">
        <h1>{LocalizationManager.localize("Hupsista!", this.props.event)}</h1>
        <h2>
          {LocalizationManager.localize(
            "Valitettavasti tästä osoitteesta ei löydy tapahtumaa. Varmistathan, että antamasi osoite on oikein.",
            this.props.event
          )}{" "}
          <span aria-labelledby="emoji" role="img">
            ✌️
          </span>
        </h2>
      </div>
    );
  };

  _renderEventNotStartedOverlay = () => {
    const { event } = this.props;
    const defaultTitle = (
      <>
        {LocalizationManager.localize(
          "Palaa takaisin hieman myöhemmin.",
          this.props.event
        )}{" "}
        <span aria-labelledby="emoji" role="img">
          🕓
        </span>
      </>
    );
    const defaultSubtitle =
      event.language == "en"
        ? `This event page will open ${
            moment(event.open_date).format("DD.MM.YYYY HH:mm") || "later"
          }.`
        : `Tämän tapahtuman tapahtumasivu aukeaa ${
            moment(event.open_date).format("DD.MM.YYYY HH:mm") || "myöhemmin"
          }.`;
    return (
      <div className="overlay">
        <h1>{event.open_date_title ? event.open_date_title : defaultTitle}</h1>
        <h2>
          {event.open_date_subtitle
            ? event.open_date_subtitle
            : defaultSubtitle}
        </h2>
      </div>
    );
  };

  _sendPollAnswer = () => {
    const pollKey = this.state.shownPoll.key;
    this.setState({ shownPoll: null });
    FirebaseHelper.sendPollAnswer(
      this.props.event,
      pollKey,
      this.state.pollAnswer
    );
  };

  _setPollAnswer = (e) => {
    this.setState({ pollAnswer: e.target.value });
  };

  _renderPollOverlay = () => {
    const { shownPoll } = this.state;
    if (!shownPoll) return null;
    return (
      <div className="overlay">
        <div className={styles.pollContainer}>
          <h2>{shownPoll.question}</h2>
          <div className={styles.options}>
            {shownPoll.options.map((option, i) => {
              return (
                <div className={styles.option}>
                  <input
                    onChange={this._setPollAnswer}
                    type="radio"
                    id={i}
                    name="option"
                    value={i}
                  />
                  <label for={i}>{option.title}</label>
                </div>
              );
            })}
          </div>
          <button onClick={this._sendPollAnswer}>
            {LocalizationManager.localize("Lähetä vastaus", this.props.event)}
          </button>
        </div>
      </div>
    );
  };

  _renderMobileChat = () => {
    const { event, admin } = this.props;
    return (
      <>
        <div
          style={{
            top: this.state.showChat ? 0 : "100%",
            left: 0,
            right: 0,
            height: "100%",
          }}
          className="chat-overlay"
        >
          <Chat
            admin={admin}
            ref={this._mobileChat}
            polls={this.state.polls}
            messages={this.state.messages}
            loading={this.state.loadingMessages}
            toggleChat={this._toggleChat}
            showTopBar
            event={event}
            width={this.state.width}
            loadingReactions={this.state.loadingReactions}
            reactions={this.state.reactions}
          />
        </div>
        <div
          onClick={this._toggleChat}
          className={`chat-btn ${event.key == "reilukauppa" ? "pink" : ""}`}
          style={
            event.styleTemplate == "jkl"
              ? {
                  backgroundColor: "#2e3184",
                }
              : undefined
          }
        >
          <MdChat
            className="icon"
            color={event.styleTemplate == "jkl" ? "white" : "white"}
          />
        </div>
      </>
    );
  };

  eventNotStarted = () => {
    const { event } = this.props;
    if (!event.key || !event.open_date) return false;

    return moment(event.open_date, "YYYY-MM-DD HH:mm").isAfter(
      moment(),
      "minute"
    );
  };

  _renderMainContent = () => {
    const { loggedIn, admin, shownPoll } = this.state;
    const { event, resetSubEvent } = this.props;
    if (admin) {
      return <Admin event={event} resetSubEvent={resetSubEvent} />;
    }
    const eventNotStarted = this.eventNotStarted();
    return (
      <>
        {shownPoll && this._renderPollOverlay()}
        <div
          className={`main-content ${
            !event.key ||
            ((event.require_ticket || this.isAdmin()) && !loggedIn) ||
            eventNotStarted ||
            !!shownPoll
              ? "blur"
              : ""
          }`}
          style={
            event.styleTemplate == "jkl"
              ? {
                  backgroundColor: "#fff6af",
                }
              : undefined
          }
        >
          <div className="content-wrapper">
            <div className="top-content">{this._renderTopContent()}</div>
            {!event.noReactions && (
              <ReactionCounter
                loading={this.state.loadingReactions}
                reactions={this.state.reactions}
                event={event}
              />
            )}
            <div ref={this._showSupportTitle} className="bottom-content">
              {event.key == "reilukauppa" && (
                <img src={require("../../assets/rk_bg.jpg")} alt="Custom BG" />
              )}
              <div className="wide">
                <ArtistInfo event={event} />
              </div>
              <div className="narrow">
                <EventTabs event={event} />
              </div>
            </div>
          </div>
          <Footer event={this.props.event} />
        </div>
        {this.state.width < 1200 &&
          !((event.require_ticket || this.isAdmin()) && !loggedIn) &&
          !event.noChat &&
          event.key &&
          this._renderMobileChat()}
      </>
    );
  };

  render() {
    const { loggedIn, checkingInitialPasscode } = this.state;
    const { event } = this.props;
    if (checkingInitialPasscode) {
      return (
        <div className="loading">
          <PropagateLoader loading={true} color="#FF871A" margin={20} />
        </div>
      );
    }

    const eventNotStarted = this.eventNotStarted();

    return (
      <div
        style={{ overflow: this.state.showChat ? "hidden" : "auto" }}
        id="event"
        className={event.key == "reilukauppa" ? "rkFonts" : ""}
      >
        {!event.key && this._renderNoEventOverlay()}
        {eventNotStarted && this._renderEventNotStartedOverlay()}
        {event.key &&
          (event.require_ticket || this.isAdmin()) &&
          !eventNotStarted &&
          !loggedIn &&
          this._renderTicketOverlay()}
        {this._renderMainContent()}
      </div>
    );
  }
}

export default withRouter(Event);
