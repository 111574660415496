export default class LocalizationManager {
  static localizations = {
    en: {
      "Sinut kirjattiin ulos. Tämä voi johtua tapahtuman päättymisestä, tapahtumasivun vaihdosta tai siitä, että käyttämääsi pääsykoodia käytetään liian useassa laitteessa.":
        "You have been logged out. This can happen because the event has ended, you've changed to a different event page or because your passcode is being used in too many devices simultaneously.",
      "Antamasi pääsykoodi ei ole käytettävissä tänään. Ole hyvä ja tarkista pääsykoodi.":
        "This passcode is not valid today. Please check the passcode.",
      "Antamasi pääsykoodi ei ole käytettävissä. Ole hyvä ja tarkista pääsykoodi.":
        "This passcode is not valid. Please check the passcode.",
      "Maksutietojen haku epäonnistui.": "Getting payment information failed.",
      "Ladataan maksutapoja...": "Loading payment information...",
      "Siirry maksamaan valitsemalla maksutapa alta:":
        "Proceed to payment by selecting a payment method below:",
      "Takaisin tapahtuman valintaan": "Back to event selection",
      kpl: "pcs",
      Seuraava: "Next",
      Peruuta: "Cancel",
      "Tarvitset pääsykoodin katsoaksesi tätä tapahtumaa.":
        "You need a passcode to watch this event.",
      "Onko sinulla jo koodi? Kirjoita se tähän!":
        "Already have a code? Write it here!",
      "Kirjoita pääsykoodi": "Write your passcode",
      "Tarkista pääsykoodi": "Check passcode",
      "Ei vielä pääsykoodia? Tilaa yksi tai useampi virtuaalinen lippu sähköpostiisi tästä!":
        "Don't have a code? Order one or more virtual tickets to your e-mail below!",
      "Kirjoita sähköpostiosoitteesi": "Write your e-mail address",
      "HUOM! Varmista, että sähköpostiosoite on kirjoitettu oikein ennen kuin jatkat seuraavaan vaiheeseen.":
        "Attention! Please make sure that your e-mail address is written correctly before proceeding to the next step.",
      "Siirry tilaamaan": "Continue order",
      "Valitettavasti tästä osoitteesta ei löydy tapahtumaa. Varmistathan, että antamasi osoite on oikein.":
        "Unfortunately no event can be found at this address. Please make sure you've written the address correctly.",
      "Palaa takaisin hieman myöhemmin.": "Come back a little bit later.",
      "Lähetä vastaus": "Send answer",
      "Vastaus lähetetty!": "Answer sent!",
      "Tämä tapahtuma pitää sisällään useamman tapahtuman. Valitse alla olevista se, jota haluat hallinnoida.":
        "This event contains multiple events. Please select the one you wish to access.",
      "Tämä tapahtuma pitää sisällään useamman tapahtuman. Valitse alla olevista se, jota haluat katsoa.":
        "This event contains multiple events. Please select the one you wish to watch.",
      "Aikataulu:": "Event schedule:",
      "Avaa ohjelma tästä": "Open schedule",
      "Lataa ohjelma tästä": "Download schedule",
      Käyttäjänimi: "Username",
      "Kirjoita viesti": "Write your message",
      "Tapahtuman järjestäjä": "Event admin",
      "Ei vielä viestejä. Aloita keskustelu!":
        "No messages yet. Start the conversation!",
      "Usein kysytyt kysymykset": "Frequently asked questions",
      Maksuehdot: "Terms of Payment",
      Käyttöehdot: "Terms of Use",
      Tietosuojaseloste: "Privacy Policy",
      "Esiintyjän chat": "Artist chat",
      Kyselyt: "Polls",
      "Tilausten seuranta": "Orders",
      Koodit: "Passcodes",
      "Päivitä lista": "Refresh list",
      "Kirjoita kysymys...": "Write the question...",
      "Lähetä kysely chattiin": "Send poll to chat",
      "Lähetä vastaukset chattiin": "Send results to chat",
      Vastausvaihtoehdot: "Answer options",
      "Kirjoita vaihtoehto...": "Write an answer option...",
      "Ääniä:": "Votes:",
      "+ Lisää vaihtoehto": "+ Add an option",
      "+ Lisää kysely": "+ Add a poll",
      Kyselyt: "Polls",
      "Tässä näkymässä voit muokata kyselyitä ja näyttää niitä streamin viereisessä chatissa.":
        "In this view you can edit polls and show them to users in the chat view next to the stream.",
      "Tallenna muutokset": "Save changes",
      "Kirjaudu ulos": "Log out",
      "Tee tilaus": "Order tickets",
      "Maksutapojen haku epäonnistui.": "Getting payment methods failed.",
    },
  };
  static localize = (string, event) => {
    if (!event) return string;
    const language = event.language;
    if (!this.localizations[language]) return string;
    return this.localizations[language][string] || string;
  };
}
