import React from "react";
import smoothscroll from "smoothscroll-polyfill";
import { withRouter, Switch, Route } from "react-router-dom";
import Event from "../Event/Event";
import FirebaseHelper from "../../FirebaseHelper";
import PropagateLoader from "react-spinners/PropagateLoader";
import "./Main.scss";
import DemoEvent from "../DemoEvent/DemoEvent";

smoothscroll.polyfill();

class Main extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: "",
      authorized: false,
      loading: false,
      loadingAuthStatus: false,
    };
    // FirebaseHelper.setOnAuthChangeListener(this._checkLoggedIn);
    this._mounted = false;
    this._setLoggedOnMount = false;
  }

  componentDidMount = () => {
    this._mounted = true;
    if (this._setLoggedOnMount) {
      this.setState({
        authorized: false,
        loadingAuthStatus: false,
      });
    }
  };

  _checkLoggedIn = (res) => {
    if (this._mounted) {
      this.setState({
        authorized: false,
        loadingAuthStatus: false,
      });
    } else {
      this._setLoggedOnMount = true;
    }
  };

  _onLogin = () => {
    this.setState({ authorized: false });
  };

  _onValueChange = (e) => {
    this.setState({
      inputValue: e.target.value.toUpperCase(),
    });
  };

  _getEventByCode = () => {
    const { inputValue } = this.state;
    this.setState({ loading: true }, () => {
      if (
        inputValue.toLowerCase() ===
        process.env.REACT_APP_TA_SECRET.toLowerCase()
      ) {
        FirebaseHelper.logIn()
          .then(() => {
            this.setState({
              authorized: true,
              loading: false,
              inputValue: "",
            });
          })
          .catch((err) => {
            this.setState({ loading: false, inputValue: "" });
            alert("Sisäänkirjautuminen epäonnistui!");
          });
      } else {
        this.setState({ loading: false, inputValue: "" });
        alert("Antamallasi koodilla ei löydy tapahtumaa!");
      }
    });
  };

  _closeEvent = () => {
    this.props.history.push("/");
    FirebaseHelper.logOut();
    this.setState({
      authorized: false,
    });
  };

  renderDemoContent = () => {
    return <DemoEvent handleClose={this._closeEvent} />;
  };

  renderDefaultContent = () => {
    const { inputValue, authorized, loading, loadingAuthStatus } = this.state;
    if (loadingAuthStatus) return null;
    return authorized ? (
      <Event handleClose={this._closeEvent} />
    ) : (
      <div id="main">
        <div className="card">
          {loading ? (
            <div className="loading">
              <PropagateLoader loading={true} color="#FF871A" margin={20} />
            </div>
          ) : (
            <div className="content">
              <img src={require("../../assets/logo.svg")} alt="Logo" />
              Kirjoita tapahtumakoodi tähän:
              <input
                onKeyUp={(e) => {
                  if (e.keyCode === 13 && inputValue) {
                    e.preventDefault();
                    this._getEventByCode();
                  }
                }}
                value={inputValue}
                placeholder="Kirjoita koodi"
                onChange={this._onValueChange}
              />
              <button
                style={{ opacity: inputValue ? 1 : 0.5 }}
                disabled={!inputValue}
                onClick={this._getEventByCode}
              >
                Siirry tapahtumaan
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    return (
      <Switch>
        <Route path="/demo">
          {this.renderDemoContent()}
        </Route>
        <Route path="/">
          {this.renderDefaultContent()}
        </Route>
      </Switch>
    )
  }
}

export default withRouter(Main);
