import React from "react";
import styles from "./PrivacyPolicy.module.scss";

class Terms extends React.PureComponent {
  render() {
    return (
      <div id={styles.policyPage}>
        <img alt="" src={require("../assets/ves_logo_dark.svg")} />
        <h1>Käyttöehdot</h1>
        <h2>Palveluntarjoaja</h2>
        <div className={styles.contacts}>
          <p>Virtual Event Studio Oy</p>
          <p>3169244-1</p>
          <p>Hämeenkatu 5 F 38</p>
          <p>40100 Jyväskylä</p>
          <p>+358 44 978 3638</p>
        </div>
        <p>
          Virtuaalisen livestream-palvelun tuottaa Virtual Event Studio Oy
          (Y-tunnus: 3169244-1). Tapahtumanjärjestänänä toimii tapahtuman
          järjestävä taho (esimerkiksi yritys, urheiluseura tms.).
        </p>
        <p>
          Virtual Event Studion asiakaspalvelu:{" "}
          <a href="mailto:support@virtualeventstudio.fi">
            support@virtualeventstudio.fi
          </a>
        </p>
        <h2>Virtuaalinen tapahtuma / livestream</h2>
        <p>
          Virtual Event Studio toimii tapahtuman ja lippujen välittäjänä
          asiakkaan ja tapahtuman järjestäjän välillä. Virtual Event Studio
          vastaa tapahtuman teknisestä livestriimauksesta ja maksupalvelun
          toteutumisesta omalla verkkoalustalla.
        </p>
        <p>
          Tapahtuman järjestäjä vastaa tapahtumasta, mahdollisista muutoksista,
          peruutuksista ja tiedotuksesta tapahtumaan liittyen. Tapahtuman
          järjestäjä vastaa myös arvonlisäverosta laissa määritellyllä tavalla
          sekä mahdollisista sisältöön liittyvistä tekijänoikeusluvista ja
          -maksuista.
        </p>
        <h2>Virtuaalisen tapahtumalipun toimitusehdot</h2>
        <p>
          Lippua ostettaessa asiakkaan tulee aika tarkistaa tapahtuman ja lipun
          tiedot. Ostettua lippua ei vaihdeta, palauteta tai lunasteta takaisin.
          Tapahtuman järjestäjä voi tehdä tähän erillisellä pyynnöllä
          poikkeuksen. Mahdollisessa teknisessä ongelmatilanteessa (esimerkiksi
          tuplamaksu) pyydämme ottamaan yhteyttä asiakaspalveluumme.
        </p>
        <p>
          Lippu lähetetään heti maksun vahvistuttua asiakkaan ilmoittamaan
          sähköpostiosoitteeseen. Muistathan varmistaa oikean
          sähköpostiosoitteen lippua tilatessa. Mikäli lippua ei näy
          sähköpostissa tai roskapostikansiossa 15 minuutin kuluessa, otathan
          yhteyden asiakaspalveluumme.
        </p>
        <h2>Maksupalvelun tarjoaja</h2>
        <p>
          Maksupalvelun toteuttaa Checkout Finland Oy (y-tunnus 2196606-6).
          Checkout Finland Oy on osa OP Ryhmää. Reklamaatiotapauksissa pyydämme
          ottamaan yhteyttä ensisijaisesti Virtual Event Studion
          asiakaspalveluun (
          <a href="mailto:support@virtualeventstudio.fi">
            support@virtualeventstudio.fi
          </a>
          ). Maksupalvelun ehdot löydät Maksuehdot-sivulta.
        </p>
        <h2>Muutokset</h2>
        <p>
          Virtual Event Studio pidättää oikeuden muuttaa ja päivittää yleisiä
          ehtoja ja tehdä niihin lisäyksiä palvelun kehittyessä.
        </p>
        <h2>Riitojen ratkaisu ja sovellettava laki</h2>
        <p>
          Toimitusehtoihin ja niiden tulkintaan sovelletaan Suomen lakia ja
          maalaisjärkeä. Mahdolliset erimielisyydet pyritään ratkaisemaan
          yhteisymmärryksessä kuluttajan ja palvelun tuottajan kanssa (
          <a href="mailto:support@virtualeventstudio.fi">
            support@virtualeventstudio.fi
          </a>
          ). Mikäli asia ei ratkea, voidaan asia ratkaista Keski-Suomen
          käräjäoikeudessa. Kuluttaja voi myös viedä erimielisyydet
          kuluttajariitalautakunnan ratkaistavaksi.
        </p>
        <h2>Toimitusehtojen voimassaolo</h2>
        <p>
          Toimitusehdot ovat toistaiseksi voimassa 20.11.2020 alkaen. Asiakas
          hyväksyy ehdot ostaessaan virtuaalisen tapahtumalipun
          live.virtualeventstudio.fi-palvelusta.
        </p>
      </div>
    );
  }
}

export default Terms;
