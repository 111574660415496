import React from "react";
import FirebaseHelper from "../../FirebaseHelper";
import styles from "./Analytics.module.scss";

class Analytics extends React.PureComponent {
  sections = [
    {
      title: "Tilauksia tehty",
      key: "orderAmount",
      postfix: " kpl",
    },
    {
      title: "Lippuja myyty yhteensä",
      key: "ticketAmount",
      postfix: " kpl",
    },
    {
      title: "Arvio lipputuloista",
      key: "orderTotal",
      postfix: " €",
    },
  ];

  state = {
    orderAmount: 0,
    ticketAmount: 0,
    orderTotal: 0,
  };

  getTotalPrice = (ticketData, onlyNumber = false) => {
    let total = 0;
    if (!Array.isArray(ticketData) && !!ticketData) {
      if (ticketData.price == undefined) {
        Object.values(ticketData).forEach((ticket) => {
          total += Number(ticket.quantity || "0") * Number(ticket.price || "0");
        });
      } else {
        total =
          Number(ticketData.quantity || "0") * Number(ticketData.price || "0");
      }
    } else {
      total = (ticketData || []).reduce((total, ticket) => {
        total += Number(ticket.quantity || "0") * Number(ticket.price || "0");
        return total;
      }, 0);
    }
    return onlyNumber ? total : `${total > 0 ? total : "-"} €`;
  };

  componentDidMount = () => {
    this.getOrders();
  };

  getOrders = () => {
    FirebaseHelper.getOrders(this.props.event).then((unfilteredOrders) => {
      const orders = (unfilteredOrders || []).filter((o) => !!o.completed);
      let orderTotal = 0;
      let orderAmount = orders.length;
      let ticketAmount = 0;
      orders.forEach((o) => {
        orderTotal += this.getTotalPrice(o.ticketData, true);
        let amount = 0;
        if (Array.isArray(o.ticketData)) {
          amount = o.ticketData?.reduce(
            (prev, td) => (prev += Number(td.quantity)),
            0
          );
        } else {
          if (o.ticketData?.price == undefined) {
            Object.values(o.ticketData || {}).forEach((ticket) => {
              amount += Number(ticket.quantity || "0");
            });
          } else {
            amount = Number(o.ticketData?.quantity || "0");
          }
        }
        ticketAmount += amount;
      });
      this.setState({ orders, orderTotal, orderAmount, ticketAmount });
    });
  };

  render() {
    return (
      <div id={styles.analytics}>
        {this.sections.map((section) => {
          return (
            <div className={styles.section}>
              <h2>{section.title}</h2>
              <h1>{`${this.state[section.key] || 0}${
                section.postfix || ""
              }`}</h1>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Analytics;
