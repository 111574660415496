import React from "react";
import Button from "../Button/Button";
import moment from "moment";
import FirebaseHelper from "../../FirebaseHelper";
import {
  CellMeasurerCache,
  List,
  CellMeasurer,
  AutoSizer,
} from "react-virtualized";
import { MdArrowDropDown, MdClose, MdDelete } from "react-icons/md";
import { forbiddenWords } from "../../constants";
import Axios from "axios";
import ReactionCounter from "../ReactionCounter/ReactionCounter";
import "./Chat.scss";
import BarChart from "./BarChart";
import LocalizationManager from "../../LocalizationManager";
class Chat extends React.PureComponent {
  constructor(props) {
    super(props);

    this._cache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 50,
    });
    this._messageList = React.createRef();
    this._messageInput = React.createRef();
    this._shouldScrollToBottom = false;
    let user = "";
    try {
      user = localStorage.getItem("palatsi_live_chat_user") || "";
    } catch (e) {}

    this._forbiddenWords = forbiddenWords;
    this._isInBottom = true;
    this._dontScrollToBottom = false;
    this._scrollOffset = 0;

    let answeredPolls = {};

    try {
      const pollString = localStorage.getItem("ves_answered_polls");
      if (pollString) {
        answeredPolls = JSON.parse(pollString);
      }
    } catch (e) {
      console.log(e);
    }

    this.state = {
      user: this.props.admin
        ? LocalizationManager.localize(
            "Tapahtuman järjestäjä",
            this.props.event
          )
        : user,
      message: "",
      messages: this.props.messages,
      newContent: false,
      calmTimer: 0,
      reactionsBlocked: false,
      flyingEmoji: null,
      pollAnswers: {},
      answeredPolls,
    };
  }

  componentDidUpdate = (oldProps) => {
    if (oldProps.admin !== this.props.admin) {
      this.setState({
        user: this.props.admin
          ? LocalizationManager.localize(
              "Tapahtuman järjestäjä",
              this.props.event
            )
          : this.state.user,
      });
    }
    if (this.props.polls !== oldProps.polls) {
      this.clearLayoutCache();
      this._messageList.current && this._messageList.current.forceUpdateGrid();
    }
  };

  componentWillReceiveProps = (newProps) => {
    if (newProps.messages !== this.props.messages) {
      if (this._scrollOffset === 0) {
        this._shouldScrollToBottom = true;
      }
      if (!this._isInBottom && !this._shouldScrollToBottom) {
        this._dontScrollToBottom = true;
      } else if (this._shouldScrollToBottom) {
        this._shouldScrollToBottom = false;
      }
      this.clearLayoutCache();
      this.setState({ messages: newProps.messages }, () => {
        this._messageList.current &&
          this._messageList.current.forceUpdateGrid();
        if (this._dontScrollToBottom) {
          this.setState({ newContent: true }, () => {
            this._dontScrollToBottom = false;
          });
        } else {
          setTimeout(() => {
            this._scrollToBottom();
          }, 300);
        }
      });
    }
  };

  componentDidMount = async () => {
    this._getForbiddenWords();
  };

  _getForbiddenWords = () => {
    const forbiddenWords = Axios.get(
      process.env.PUBLIC_URL + "/pl_constants.json"
    ).then((res) => {
      const data = res.data;
      this._forbiddenWords = data.forbiddenWords || forbiddenWords;
    });
  };

  _validate = (message) => {
    // this._forbiddenWords.forEach((word) => {
    //   const wordToCheck = message
    //     .toLowerCase()
    //     .replace(/1/g, "i")
    //     .replace(/3/g, "e")
    //     .replace(/4/g, "a")
    //     .replace(/7/g, "t")
    //     .replace(/0/g, "o")
    //     .replace(/5/g, "s");
    //   if (wordToCheck.search(word) !== -1) {
    //     const re = new RegExp(word, "g");
    //     message = message.replace(re, "ihana");
    //   }
    // });
    const words = message.split(" ");
    words.forEach((word, i) => {
      const wordToCheck = word
        .toLowerCase()
        .replace(/1/g, "i")
        .replace(/3/g, "e")
        .replace(/4/g, "a")
        .replace(/7/g, "t")
        .replace(/0/g, "o")
        .replace(/5/g, "s");
      if (this._forbiddenWords.indexOf(wordToCheck) !== -1) {
        words[i] = "ihana";
      }
    });
    const sentence = words.join(" ");
    return sentence;
  };

  _msgCount = 0;
  _startedSending = false;
  _calmInterval = null;

  _checkIfInsane = () => {
    if (!this._startedSending) {
      this._startedSending = true;
      setTimeout(() => {
        this._msgCount = 0;
        this._startedSending = false;
      }, 5000);
    }

    if (this._startedSending) {
      this._msgCount++;
      if (this._msgCount >= 8) {
        this.setState(
          {
            calmTimer: 10,
          },
          () => {
            this._calmInterval = setInterval(() => {
              const calmTimer = this.state.calmTimer - 1;
              this.setState({
                calmTimer,
              });
              if (calmTimer === 0 && this._calmInterval)
                clearInterval(this._calmInterval);
            }, 1000);
          }
        );
      }
    }
  };

  _containsLinks = (message) => {
    const expression = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
    const regex = new RegExp(expression);
    return (message || "").match(regex);
  };

  _sendMessage = () => {
    const { admin } = this.props;
    if (
      this.state.calmTimer > 0 ||
      this.state.message.length === 0 ||
      this.state.user.length === 0
    ) {
      return;
    }

    this._checkIfInsane();

    if (
      !admin &&
      (this._containsLinks(this.state.message) ||
        this._containsLinks(this.state.user))
    ) {
      alert("Ethän lähetä linkkejä chattiin.");
      return;
    }

    const data = {
      user_name: this._validate(this.state.user),
      is_customer: !!admin,
      message: this._validate(this.state.message),
      utc: true,
      timestamp: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
    };
    this.setState({ message: "" });
    this._shouldScrollToBottom = true;
    FirebaseHelper.sendMessage(data, this.props.event);
  };

  clearLayoutCache = () => {
    if (!this._cache) return;
    this._cache.clearAll();
  };

  _sendReaction = (reaction) => {
    this.setState({ flyingEmoji: reaction, reactionsBlocked: true }, () => {
      setTimeout(() => {
        this.setState({ reactionsBlocked: false, flyingEmoji: null });
      }, 2000);
      FirebaseHelper.sendReaction(reaction, this.props.event);
    });
  };

  _setUser = (e) => {
    localStorage.setItem("palatsi_live_chat_user", e.currentTarget.value);
    this.setState({
      user: e.currentTarget.value,
    });
  };

  _setMessage = (e) => {
    this.setState({
      message: e.currentTarget.value,
    });
  };

  _scrollToBottom = () => {
    this.setState({ newContent: false });
    const messages = this._messageList.current;
    if (!messages) return false;
    messages.scrollToRow(this.state.messages.length - 1);
  };

  stringToColour = (str) => {
    var hash = 0;
    if (!str) str = "";
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = "#";
    for (let j = 0; j < 3; j++) {
      var value = (hash >> (j * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }
    return colour;
  };

  _onScroll = ({ clientHeight, scrollHeight, scrollTop }) => {
    this._scrollOffset = scrollTop;
    const scroll = scrollHeight - scrollTop;
    const height = clientHeight;
    const isInBottom = scroll <= height + 50;
    if (isInBottom && this.state.newContent) {
      this.setState({ newContent: false });
    }
    this._isInBottom = isInBottom;
  };

  _sendPollAnswer = (poll) => {
    const answer = this.state.pollAnswers[poll.key];
    if (!answer) return;
    const answeredPolls = {
      ...this.state.answeredPolls,
      [poll.key]: true,
    };
    try {
      localStorage.setItem("ves_answered_polls", JSON.stringify(answeredPolls));
    } catch (e) {
      console.log(e);
    }
    this.setState({ answeredPolls }, () => {
      this._messageList.current && this._messageList.current.forceUpdateGrid();
    });
    FirebaseHelper.sendPollAnswer(this.props.event, poll.key, answer);
  };

  _setPollAnswer = (e, poll) => {
    const pollAnswers = {
      ...this.state.pollAnswers,
      [poll.key]: e.target.value,
    };
    this.setState({ pollAnswers });
  };

  removeMessage = (key) => {
    const confirmation = window.confirm(
      "Oletko varma, että haluat poistaa tämän viestin?"
    );
    if (!confirmation) return;
    FirebaseHelper.deleteMessage(this.props.event, key);
  };

  _rowRenderer = ({ index: i, key, parent, style }) => {
    const { event, admin } = this.props;
    const message = this.state.messages[i];

    return (
      <CellMeasurer
        cache={this._cache}
        columnIndex={0}
        key={key}
        rowIndex={i}
        parent={parent}
      >
        {({ registerChild }) => {
          if (message.poll) {
            const poll = this.props.polls[message.poll.key];
            const hasAnswered = this.state.answeredPolls[poll?.key] === true;
            return (
              <div
                ref={registerChild}
                key={i}
                className="message-row"
                style={{ ...style, backgroundColor: "rgba(0,0,0,0.3)" }}
              >
                {!this.props.viewMode && (
                  <img
                    alt="Palatsi Live logo"
                    src={require("../../assets/wave_logo.svg")}
                  />
                )}
                <div className="texts">
                  <div className="left">
                    <h2 className="highlight">
                      {message.is_customer
                        ? LocalizationManager.localize(
                            "Tapahtuman järjestäjä",
                            this.props.event
                          )
                        : "Virtual Event Studio"}
                    </h2>
                    {!poll && <p>Kyselyä ei löytynyt.</p>}
                    {!!poll && (
                      <div className="chat-poll">
                        <p
                          style={{
                            marginBottom: message.displayAnswers ? 5 : 10,
                          }}
                        >
                          {poll.question}
                        </p>
                        {message.displayAnswers ? (
                          <div>
                            <BarChart
                              viewMode={this.props.viewMode}
                              data={poll.options}
                            />
                          </div>
                        ) : (
                          <div
                            className={`poll-options ${
                              hasAnswered ? "answered" : ""
                            }`}
                          >
                            {poll.options.map((option, i) => {
                              return (
                                <div className="poll-option">
                                  <input
                                    onChange={(e) =>
                                      this._setPollAnswer(e, poll)
                                    }
                                    type="radio"
                                    id={i}
                                    name="option"
                                    value={i}
                                  />
                                  <label for={i}>{option.title}</label>
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {!message.displayAnswers && (
                          <button
                            disabled={hasAnswered}
                            onClick={() => this._sendPollAnswer(poll)}
                          >
                            {hasAnswered
                              ? LocalizationManager.localize(
                                  "Vastaus lähetetty!",
                                  this.props.event
                                )
                              : LocalizationManager.localize(
                                  "Lähetä vastaus",
                                  this.props.event
                                )}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                  {admin && (
                    <button
                      className="close"
                      onClick={() => this.removeMessage(message.key)}
                    >
                      <MdDelete />
                    </button>
                  )}
                  <div className="right">
                    <p className="time">
                      {(message.utc
                        ? moment
                            .utc(message.timestamp, "YYYY-MM-DD HH:mm:ss")
                            .local()
                        : moment(message.timestamp, "YYYY-MM-DD HH:mm:ss")
                      ).format("DD.MM.YYYY")}
                    </p>
                    <p className="time">
                      {(message.utc
                        ? moment
                            .utc(message.timestamp, "YYYY-MM-DD HH:mm:ss")
                            .local()
                        : moment(message.timestamp, "YYYY-MM-DD HH:mm:ss")
                      )
                        .locale("fi")
                        .format("HH:mm")}
                    </p>
                  </div>
                </div>
                <hr />
              </div>
            );
          }

          return message.is_admin || message.is_customer ? (
            <div
              ref={registerChild}
              key={i}
              className="message-row"
              style={{ ...style, backgroundColor: "rgba(0,0,0,0.3)" }}
            >
              {!this.props.viewMode && (
                <img
                  alt="Palatsi Live logo"
                  src={require("../../assets/wave_logo.svg")}
                />
              )}
              <div className="texts">
                <div className="left">
                  <h2 className="highlight">
                    {message.is_customer
                      ? LocalizationManager.localize(
                          "Tapahtuman järjestäjä",
                          this.props.event
                        )
                      : "Virtual Event Studio"}
                  </h2>
                  <p
                    style={{
                      maxWidth: this.props.showTopBar
                        ? "calc(100vw - 200px)"
                        : 250,
                    }}
                  >
                    {message.message}
                  </p>
                </div>
                {admin && (
                  <button
                    className="close"
                    onClick={() => this.removeMessage(message.key)}
                  >
                    <MdDelete />
                  </button>
                )}
                <div className="right">
                  <p className="time">
                    {(message.utc
                      ? moment
                          .utc(message.timestamp, "YYYY-MM-DD HH:mm:ss")
                          .local()
                      : moment(message.timestamp, "YYYY-MM-DD HH:mm:ss")
                    ).format("DD.MM.YYYY")}
                  </p>
                  <p className="time">
                    {(message.utc
                      ? moment
                          .utc(message.timestamp, "YYYY-MM-DD HH:mm:ss")
                          .local()
                      : moment(message.timestamp, "YYYY-MM-DD HH:mm:ss")
                    )
                      .locale("fi")
                      .format("HH:mm")}
                  </p>
                </div>
              </div>
              <hr />
            </div>
          ) : (
            <div
              ref={registerChild}
              className="message-row"
              style={style}
              key={i}
            >
              {!this.props.viewMode && (
                <div
                  style={{
                    backgroundColor:
                      event.key === "reilukauppa"
                        ? i % 2
                          ? "#BED600"
                          : "#00B9E4"
                        : (event.styleTemplate == 'jkl' ? (i % 2 ? '#f68e6b' : '#2e3184') : this.stringToColour(message.user_name)),
                  }}
                  className="line"
                />
              )}
              <div className="texts">
                <div className="left">
                  <h2>{message.user_name}</h2>
                  <p
                    style={{
                      maxWidth: this.props.showTopBar
                        ? "calc(100vw - 200px)"
                        : 250,
                    }}
                  >
                    {message.message}
                  </p>
                </div>
                {admin && (
                  <button
                    className="close"
                    onClick={() => this.removeMessage(message.key)}
                  >
                    <MdDelete />
                  </button>
                )}
                <div className="right">
                  <p className="time">
                    {(message.utc
                      ? moment
                          .utc(message.timestamp, "YYYY-MM-DD HH:mm:ss")
                          .local()
                      : moment(message.timestamp, "YYYY-MM-DD HH:mm:ss")
                    ).format("DD.MM.YYYY")}
                  </p>
                  <p className="time">
                    {(message.utc
                      ? moment
                          .utc(message.timestamp, "YYYY-MM-DD HH:mm:ss")
                          .local()
                      : moment(message.timestamp, "YYYY-MM-DD HH:mm:ss")
                    )
                      .locale("fi")
                      .format("HH:mm")}
                  </p>
                </div>
              </div>
              <hr />
            </div>
          );
        }}
      </CellMeasurer>
    );
  };

  _getEmojiFromKey = (key) => {
    switch (key) {
      case "heart":
        return "❤️";
      case "clap":
        return "👏";
      case "fire":
        return "🔥";
      default:
        return "";
    }
  };

  _getXFromKey = (key) => {
    switch (key) {
      case "heart":
        return 45;
      case "clap":
        return 20;
      case "fire":
        return 85;
      default:
        return 85;
    }
  };

  render() {
    const { admin, event } = this.props;
    const { user, message, newContent, reactionsBlocked } = this.state;
    if (event.chat_disabled) {
      return (
        <div
          style={{
            backgroundColor: this.props.showTopBar
              ? "rgba(0,0,0,0.9)"
              : "#060b12",
          }}
          id="pl-chat"
          className="disabled"
        >
          {this.props.showTopBar && (
            <div className="chat-top-bar">
              <button onClick={this.props.toggleChat}>
                <MdClose className="icon" />
              </button>
            </div>
          )}
          <div className="flex">
            <p className="loading-chat">Chat on poistettu käytöstä.</p>
          </div>
        </div>
      );
    }
    return (
      <div
        style={{
          backgroundColor: this.props.showTopBar
            ? "rgba(0,0,0,0.9)"
            : "#060b12",
        }}
        id="pl-chat"
      >
        {this.props.loading ? (
          <p className="loading-chat">Ladataan chattia...</p>
        ) : (
          <>
            {this.props.showTopBar && (
              <div className="chat-top-bar">
                <ReactionCounter
                  loading={this.props.loadingReactions}
                  reactions={this.props.reactions}
                  event={this.props.event}
                />
                <button onClick={this.props.toggleChat}>
                  <MdClose className="icon" />
                </button>
              </div>
            )}
            <div id="messages">
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    ref={this._messageList}
                    deferredMeasurementCache={this._cache}
                    height={height}
                    overscanRowCount={0}
                    onScroll={this._onScroll}
                    estimatedRowSize={100}
                    rowCount={this.state.messages.length}
                    rowHeight={this._cache.rowHeight}
                    rowRenderer={this._rowRenderer}
                    width={width}
                    scrollToIndex={
                      this._dontScrollToBottom
                        ? undefined
                        : this.state.messages.length - 1
                    }
                  />
                )}
              </AutoSizer>
              {this.state.messages.length === 0 && (
                <div className="no-messages">
                  <p>
                    {LocalizationManager.localize(
                      "Ei vielä viestejä. Aloita keskustelu!",
                      this.props.event
                    )}
                  </p>
                </div>
              )}
            </div>
            {!this.props.viewMode && (
              <div
                style={{
                  backgroundColor: this.props.showTopBar
                    ? "rgba(0, 0, 0, 0.8)"
                    : "transparent",
                }}
                className="edit-container"
              >
                <div className="inputs">
                  <input
                    placeholder={LocalizationManager.localize(
                      "Käyttäjänimi",
                      this.props.event
                    )}
                    disabled={!!admin}
                    value={user}
                    onChange={this._setUser}
                    onKeyUp={(e) => {
                      if (
                        e.keyCode === 13 &&
                        user.length > 0 &&
                        this._messageInput.current
                      ) {
                        e.preventDefault();
                        this._messageInput.current.focus();
                      }
                    }}
                  />
                  <input
                    ref={this._messageInput}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13 && message.length > 0) {
                        e.preventDefault();
                        this._sendMessage();
                      }
                    }}
                    disabled={this.state.calmTimer > 0}
                    placeholder={LocalizationManager.localize(
                      "Kirjoita viesti",
                      this.props.event
                    )}
                    value={message}
                    onChange={this._setMessage}
                  />
                </div>
                <div className="btns">
                  <div
                    style={{
                      opacity: reactionsBlocked ? 0.5 : 1,
                      pointerEvents: reactionsBlocked ? "none" : "auto",
                    }}
                    className="reactions"
                  >
                    <div onClick={() => this._sendReaction("fire")}>
                      <span
                        role="img"
                        aria-label="Fire emoji"
                        className="emoji"
                      >
                        🔥
                      </span>
                    </div>
                    <div onClick={() => this._sendReaction("heart")}>
                      <span
                        role="img"
                        aria-label="Heart emoji"
                        className="emoji"
                      >
                        ❤️
                      </span>
                    </div>
                    <div onClick={() => this._sendReaction("clap")}>
                      <span
                        role="img"
                        aria-label="Clap emoji"
                        className="emoji"
                      >
                        👏
                      </span>
                    </div>
                  </div>
                  <Button
                    disabled={user.length === 0 || message.length === 0}
                    style={{
                      opacity: user.length > 0 && message.length > 0 ? 1 : 0.2,
                      backgroundColor: event.styleTemplate == 'jkl' ? "#2e3184" : event.customLayout?.highlightColor,
                      color: (event.key == 'reilukauppa') ? '#000' : event.customLayout?.textColor
                    }}
                    title="Lähetä"
                    onClick={this._sendMessage}
                  />
                </div>
                {this.state.calmTimer > 0 && (
                  <div className="calm-overlay">
                    Rauhoitu, hengitä syvään... ({this.state.calmTimer})
                  </div>
                )}
              </div>
            )}
          </>
        )}
        {newContent && (
          <div onClick={this._scrollToBottom} className="new-content">
            Uusia viestejä <MdArrowDropDown className="icon" />
          </div>
        )}
        {this.state.flyingEmoji && (
          <div className={this.props.showTopBar ? "mobile-flying" : "flying"}>
            <span
              style={{ right: this._getXFromKey(this.state.flyingEmoji) }}
              role="img"
              aria-label="Fire emoji"
              className="flying-emoji"
            >
              {this._getEmojiFromKey(this.state.flyingEmoji)}
            </span>
            <span
              style={{ right: this._getXFromKey(this.state.flyingEmoji) }}
              role="img"
              aria-label="Flying emoji"
              className="flying-emoji"
            >
              {this._getEmojiFromKey(this.state.flyingEmoji)}
            </span>
            <span
              style={{ right: this._getXFromKey(this.state.flyingEmoji) }}
              role="img"
              aria-label="Flying emoji"
              className="flying-emoji"
            >
              {this._getEmojiFromKey(this.state.flyingEmoji)}
            </span>
            <span
              style={{ right: this._getXFromKey(this.state.flyingEmoji) }}
              role="img"
              aria-label="Flying emoji"
              className="flying-emoji"
            >
              {this._getEmojiFromKey(this.state.flyingEmoji)}
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default Chat;
