import React from "react";
import "./ThankYou.scss";

class FailEn extends React.PureComponent {
  render() {
    return (
      <div id="thank-you">
        <img alt="" src={require("../assets/ves_logo_dark.svg")} />
        <h1>
          Unfortunately the payment failed.{" "}
          <span role="img" aria-labelledby="emoji">
            😞
          </span>
        </h1>
        <h2>
          There is a possible issue with the payment method or with the payment
          handler. Please try the payment again. We're sorry for the trouble.
        </h2>
        <hr />
        <div>
          <p>
            If you have any problems, please read our{" "}
            <a href="/faq" target="_blank" rel="noopener noreferrer">
              frequently asked questions
            </a>
            .
          </p>
          <p>
            If you can't find a solution, please contact us at:{" "}
            <a href="mailto:support@virtualeventstudio.fi">
              support@virtualeventstudio.fi
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default FailEn;
