import moment from "moment";

var firebase = require("firebase/app");
require("firebase/auth");
require("firebase/database");

export default class FirebaseHelper {
  static start = () => {
    const firebaseConfig = {
      apiKey: "AIzaSyD-bNQaeMdp_MgXE-HvESO_xPL2mUERA64",
      authDomain: "palatsi-live-chat.firebaseapp.com",
      databaseURL: "https://palatsi-live-chat.firebaseio.com",
      projectId: "palatsi-live-chat",
      storageBucket: "palatsi-live-chat.appspot.com",
      messagingSenderId: "234523728201",
      appId: "1:234523728201:web:f22ace3dda6fa0e5dca298",
      measurementId: "G-9J15GMZ4VT",
    };
    firebase.initializeApp(firebaseConfig);
  };

  static setOnAuthChangeListener = (callback) => {
    firebase.auth().onAuthStateChanged((res) => {
      callback(res);
    });
  };

  static isLoggedIn = () => {
    const isLoggedIn = firebase.auth().currentUser !== null;
    return isLoggedIn;
  };

  static getCurrentUser = () => {
    return firebase.auth().currentUser;
  };

  static logIn = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => {
          firebase
            .auth()
            .signInAnonymously()
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              reject();
            });
        });
    });
  };

  static logOut = () => {
    firebase
      .auth()
      .signOut()
      .then((res) => {})
      .catch((error) => {});
  };

  static getEvent = (key) => {
    return new Promise((resolve) => {
      firebase
        .database()
        .ref("ves_events/" + key)
        .once("value")
        .then((snapshot) => {
          resolve(snapshot.val() || {});
        });
    });
  };

  static onEventChanged = (key, callback) => {
    firebase
      .database()
      .ref("ves_events/" + key)
      .on("value", callback);
  };

  static offEventChanged = (key, callback) => {
    firebase
      .database()
      .ref("ves_events/" + key)
      .off("value", callback);
  };

  static getReactions = (event) => {
    return new Promise((resolve) => {
      firebase
        .database()
        .ref("reactions/" + event.key)
        .once("value")
        .then((snapshot) => {
          resolve(snapshot.val() || {});
        });
    });
  };

  static getMessages = (event, isLive) => {
    return new Promise((resolve) => {
      firebase
        .database()
        .ref("messages/" + event.key)
        .limitToLast(isLive ? 100 : 6000)
        .once("value")
        .then((snapshot) => {
          resolve(Object.values(snapshot.val() || {}));
        });
    });
  };

  static getPolls = (event) => {
    return new Promise((resolve) => {
      const formattedKey = event.key?.split("_")[0];
      firebase
        .database()
        .ref("ves_polls/" + formattedKey)
        .once("value")
        .then((snapshot) => {
          resolve(snapshot.val() || {});
        });
    });
  };

  static getOrders = (event) => {
    return new Promise((resolve) => {
      const formattedKey = event.key.split("_")[0];
      firebase
        .database()
        .ref("transactions/" + formattedKey)
        .once("value")
        .then((snapshot) => {
          resolve(Object.values(snapshot.val() || {}));
        });
    });
  };

  static getCodes = (event) => {
    return new Promise((resolve) => {
      firebase
        .database()
        .ref("ves_codes/" + event.key)
        .once("value")
        .then((snapshot) => {
          const val = snapshot.val() || {};
          const codes = Object.keys(val).map((key) => {
            return {
              ...val[key],
              id: key,
            };
          });
          resolve(codes);
        });
    });
  };

  static editCode = (id, data, eventKey) => {
    return new Promise((resolve) => {
      firebase
        .database()
        .ref(`ves_codes/${eventKey}/${id}`)
        .update(data)
        .then(() => resolve());
    });
  };

  static getRegistrations = (event) => {
    return new Promise((resolve) => {
      firebase
        .database()
        .ref("registrations/" + event.key)
        .once("value")
        .then((snapshot) => {
          resolve(Object.values(snapshot.val() || {}));
        });
    });
  };

  static onRegistration = (event, callback) => {
    firebase
      .database()
      .ref("registrations/" + event.key)
      .on("value", callback);
  };

  static deleteMessage = (event, key) => {
    const ref = firebase
      .database()
      .ref(`messages/${event.key}/${key}`)
      .remove();
  };

  static sendMessage = (data, event) => {
    const ref = firebase.database().ref("messages/" + event.key);
    const newRef = ref.push();
    newRef.set({ ...data, key: newRef.key });
  };

  static setPollAnswered = (poll, event) => {
    const formattedKey = event.key.split("_")[0];
    if (!poll.key || !firebase.auth().currentUser) return;
    const ref = firebase
      .database()
      .ref(
        `ves_polls/${formattedKey}/${poll.key}/answered/${
          firebase.auth().currentUser.uid
        }`
      );
    ref.set(true);
  };

  static createCode = async (data, event) => {
    const ref = firebase.database().ref(`ves_codes/${event.key}`);
    const newRef = ref.push();
    newRef.set(data);
    const key = (await newRef).key;
    return key;
  };

  static removeCode = (event, id) => {
    const ref = firebase
      .database()
      .ref(`ves_codes/${event.key}/${id}`)
      .remove();
  };

  static onCodesChanged = (event, callback) => {
    firebase.database().ref(`ves_codes/${event.key}/`).on("value", callback);
  };

  static offCodesChanged = (event, callback) => {
    firebase.database().ref(`ves_codes/${event.key}/`).off("value", callback);
  };

  static createTransaction = (data, event) => {
    return new Promise((resolve, reject) => {
      const formattedKey = event.key.split("_")[0];
      firebase
        .database()
        .ref(`transactions/${formattedKey}/${data.id}`)
        .set(data)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  };

  static editEvent = (data, event) => {
    return new Promise((resolve) => {
      const formattedKey = event.sub_events
        ? event.key.split("_")[0]
        : event.key;
      let ref = `ves_events/${formattedKey}`;
      if (event.sub_events) {
        const index = event.sub_events.findIndex((e) => e.key == event.key);
        ref = `ves_events/${formattedKey}/sub_events/${index}`;
      }
      firebase
        .database()
        .ref(ref)
        .update(data)
        .then(() => resolve());
    });
  };

  static savePolls = (polls, event) => {
    return new Promise((resolve) => {
      const formattedKey = event.key.split("_")[0];
      var updates = {};
      polls.forEach((item) => {
        var newPostKey =
          item.key ||
          firebase.database().ref().child(`ves_polls/${formattedKey}/`).push()
            .key;
        updates[newPostKey] = { ...item, key: newPostKey };
      });
      firebase
        .database()
        .ref(`ves_polls/${formattedKey}`)
        .set(updates)
        .then(() => resolve());
    });
  };

  static setSession = (event, passcode, uid, admin = false) => {
    return new Promise((resolve) => {
      const ref = firebase.database().ref(`ves_sessions/${event.key}/`);
      const newRef = ref.push();
      newRef
        .set({
          uid,
          passcode,
          timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
          admin,
        })
        .then(() => {
          resolve();
        });
    });
  };

  static setAdminSession = (event, uid) => {
    return new Promise((resolve) => {
      const ref = firebase.database().ref(`ves_admin_sessions/${event.key}/`);
      const newRef = ref.push();
      newRef
        .set({
          uid,
          timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
        })
        .then(() => {
          resolve();
        });
    });
  };

  static removeAdminSession = (event, uid) => {
    firebase
      .database()
      .ref(`ves_admin_sessions/${event.key}/`)
      .orderByChild("uid")
      .equalTo(uid)
      .once("value", (snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach((child) => {
            child.ref
              .remove()
              .then(() => {})
              .catch((err) => {});
            return true;
          });
        } else {
        }
      });
  };

  static removeSession = (event, uid) => {
    firebase
      .database()
      .ref(`ves_sessions/${event.key}/`)
      .orderByChild("uid")
      .equalTo(uid)
      .once("value", (snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach((child) => {
            child.ref
              .remove()
              .then(() => {})
              .catch((err) => {});
            return true;
          });
        } else {
        }
      });
  };

  static removeOneSession = (event, passcode) => {
    firebase
      .database()
      .ref(`ves_sessions/${event.key}/`)
      .orderByChild("passcode")
      .equalTo(passcode)
      .once("value", (snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach((child) => {
            child.ref
              .remove()
              .then(() => {})
              .catch((err) => {});
            return true;
          });
        } else {
        }
      });
  };

  static onAdminSessionsChanged = (event, uid, callback) => {
    firebase
      .database()
      .ref(`ves_admin_sessions/${event.key}/`)
      .orderByChild("uid")
      .equalTo(uid)
      .on("value", callback);
  };

  static removeAdminSessionListener = (event, uid, callback) => {
    firebase
      .database()
      .ref(`ves_admin_sessions/${event.key}/`)
      .orderByChild("uid")
      .equalTo(uid)
      .off("value", callback);
  };

  static onSessionsChanged = (event, uid, callback) => {
    firebase
      .database()
      .ref(`ves_sessions/${event.key}/`)
      .orderByChild("uid")
      .equalTo(uid)
      .on("value", callback);
  };

  static removeSessionListener = (event, uid, callback) => {
    firebase
      .database()
      .ref(`ves_sessions/${event.key}/`)
      .orderByChild("uid")
      .equalTo(uid)
      .off("value", callback);
  };

  static getSessionsForUID = (event, uid) => {
    return new Promise((resolve, reject) => {
      firebase
        .database()
        .ref(`ves_sessions/${event.key}/`)
        .orderByChild("uid")
        .equalTo(uid)
        .once("value", (snapshot) => {
          if (snapshot.exists()) {
            const sessions = Object.values(snapshot.val());
            resolve(sessions);
          } else {
            resolve([]);
          }
        });
    });
  };

  static getSessions = (event, passcode) => {
    return new Promise((resolve, reject) => {
      firebase
        .database()
        .ref(`ves_sessions/${event.key}/`)
        .orderByChild("passcode")
        .equalTo(passcode)
        .once("value", (snapshot) => {
          if (snapshot.exists()) {
            const sessions = Object.values(snapshot.val());
            resolve(sessions);
          } else {
            resolve([]);
          }
        });
    });
  };

  static checkPasscode = (passcode, event) => {
    return new Promise((resolve, reject) => {
      if (passcode == process.env.REACT_APP_VES_ADMIN) {
        resolve({
          admin: true,
          amount: -1,
          passcode: process.env.REACT_APP_VES_ADMIN,
        });
        return;
      }
      firebase
        .database()
        .ref(`ves_codes/${event.key}`)
        .orderByChild("passcode")
        .equalTo(passcode)
        .once("value", (snapshot) => {
          if (snapshot.exists()) {
            const passcodeData = Object.values(snapshot.val())[0];
            resolve(passcodeData);
          } else {
            const keySplit = event.key.split("_");
            if (keySplit.length > 1) {
              const key = keySplit[0];
              FirebaseHelper.checkPasscode(passcode, { key })
                .then((data) => resolve(data))
                .catch(() => reject());
            } else {
              reject();
            }
          }
        });
    });
  };

  static checkAdminPasscode = (passcode, event) => {
    return new Promise((resolve, reject) => {
      firebase
        .database()
        .ref(`ves_admin_codes/${event.key}/`)
        .once("value", (snapshot) => {
          if (snapshot.exists()) {
            const foundCode = snapshot.val();
            if (foundCode === passcode) {
              resolve();
            } else {
              reject();
            }
          } else {
            const keySplit = event.key.split("_");
            if (keySplit.length > 1) {
              const key = keySplit[0];
              FirebaseHelper.checkAdminPasscode(passcode, { key })
                .then(() => resolve())
                .catch(() => reject());
            } else {
              reject();
            }
          }
        });
    });
  };

  static sendReaction = (reaction, event) => {
    firebase
      .database()
      .ref("reactions/" + event.key + "/" + reaction)
      .transaction((count) => {
        return (count || 0) + 1;
      });
  };

  static sendPollAnswer = (event, pollKey, optionIndex) => {
    const formattedKey = event.key.split("_")[0];
    firebase
      .database()
      .ref(`ves_polls/${formattedKey}/${pollKey}/options/${optionIndex}/count`)
      .transaction((count) => {
        return (count || 0) + 1;
      });
  };

  static onMessage = (event, callback, isLive) => {
    firebase
      .database()
      .ref("messages/" + event.key)
      .limitToLast(isLive ? 100 : 6000)
      .on("value", callback);
  };

  static onShownPollChange = (event, callback) => {
    const formattedKey = event.key?.split("_")[0];
    firebase
      .database()
      .ref(`ves_events/${formattedKey}/shown_poll`)
      .on("value", callback);
  };

  static onPollsChanged = (event, callback) => {
    const formattedKey = event.key?.split("_")[0];
    firebase.database().ref(`ves_polls/${formattedKey}/`).on("value", callback);
  };

  static removeMsgListener = (event, callback) => {
    firebase
      .database()
      .ref("messages/" + event.key)
      .off("value", callback);
  };

  static removeReactionListener = (event, callback) => {
    firebase
      .database()
      .ref("reactions/" + event.key)
      .off("value", callback);
  };

  static onReaction = (event, callback) => {
    firebase
      .database()
      .ref("reactions/" + event.key)
      .on("value", callback);
  };
}
