import React from "react";
import "./ThankYou.scss";

class Fail extends React.PureComponent {
  render() {
    return (
      <div id="thank-you">
        <img alt="" src={require("../assets/ves_logo_dark.svg")} />
        <h1>
          Valitettavasti maksutapahtuma epäonnistui.{" "}
          <span role="img" aria-labelledby="emoji">
            😞
          </span>
        </h1>
        <h2>
          Ongelma voi olla maksutavan tai maksunvälittäjän puolella. Yritäthän
          suorittaa maksun uudestaan. Pahoittelut aiheutuneesta vaivasta.
        </h2>
        <hr />
        <div>
          <p>
            Ongelmatapauksissa luethan{" "}
            <a href="/faq" target="_blank" rel="noopener noreferrer">
              usein kysytyt kysymykset
            </a>
            .
          </p>
          <p>
            Jos ongelmaan ei löydy ratkaisua, ota yhteyttä osoitteeseen{" "}
            <a href="mailto:support@virtualeventstudio.fi">
              support@virtualeventstudio.fi
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default Fail;
