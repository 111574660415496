import React from "react";
import { BarLoader } from "react-spinners";
import LocalizationManager from "../../LocalizationManager";
import "./TopBar.scss";

const TopBar = (props) => {
  const {
    onClose,
    logo,
    size,
    onLogOut,
    onSave,
    saving,
    resetSubEvent,
    admin,
  } = props;
  return (
    <div className={`top`}>
      <div className="left">
        <img
          onClick={onClose}
          alt="Event logo"
          className="logo"
          style={{ height: size, width: size }}
          src={
            logo && logo !== ""
              ? logo
              : require("../../assets/ves_logo_dark.svg")
          }
        />
        {admin && <h2>Tapahtuman hallinta</h2>}
      </div>
      <div className="buttons">
        {onSave && (
          <button className={`green ${saving && "saving"}`} onClick={onSave}>
            {saving ? (
              <BarLoader loading={true} color="white" margin={10} />
            ) : (
              LocalizationManager.localize("Tallenna muutokset", props.event)
            )}
          </button>
        )}
        {resetSubEvent && (
          <button className="red" onClick={resetSubEvent}>
            {LocalizationManager.localize(
              "Takaisin tapahtuman valintaan",
              props.event
            )}
          </button>
        )}
        {onLogOut && (
          <button className="red" onClick={onLogOut}>
            {LocalizationManager.localize("Kirjaudu ulos", props.event)}
          </button>
        )}
      </div>
    </div>
  );
};

export default TopBar;
