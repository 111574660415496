import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Main from "./pages/Main/Main";
import FirebaseHelper from "./FirebaseHelper";
import ThankYou from "./pages/ThankYou";
import "./index.css";
import EventWrapper from "./pages/Event/EventWrapper";
import Fail from "./pages/Fail";
import FAQ from "./pages/FAQ";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import ThankYouEn from "./pages/ThankYouEn";
import FailEn from "./pages/FailEn";
import { SnackbarProvider } from "notistack";

FirebaseHelper.start();

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route path="/" exact>
          <Main />
        </Route>
        <Route path="/kiitos">
          <ThankYou />
        </Route>
        <Route path="/thanks">
          <ThankYouEn />
        </Route>
        <Route path="/fail">
          <Fail />
        </Route>
        <Route path="/fail_en">
          <FailEn />
        </Route>
        <Route path="/faq">
          <FAQ />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/terms">
          <Terms />
        </Route>
        <Route path="/*">
          <SnackbarProvider maxSnack={3}>
            <EventWrapper />
          </SnackbarProvider>
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
