import React from "react";
import LocalizationManager from "../../LocalizationManager";

const Footer = (props) => {
  const { event } = props;
  return (
    <footer>
      <a
        href="https://virtualeventstudio.fi"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={require("../../assets/ves_logo_white.svg")} alt="VES Logo" />
      </a>
      <div>
        <a href="/faq" target="_blank" rel="noopener noreferrer">
          {LocalizationManager.localize("Usein kysytyt kysymykset", event)}
        </a>
        <a
          href="https://www.checkout.fi/ehdot-ja-sopimukset/maksuehdot"
          target="_blank"
          rel="noopener noreferrer"
        >
          {LocalizationManager.localize("Maksuehdot", event)}
        </a>
        <a href="/terms" target="_blank" rel="noopener noreferrer">
        {LocalizationManager.localize("Käyttöehdot", event)}
        </a>
        <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
        {LocalizationManager.localize("Tietosuojaseloste", event)}
        </a>
      </div>
    </footer>
  );
};

export default Footer;
