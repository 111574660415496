import React from "react";
import { PropagateLoader } from "react-spinners";
import queryString from "query-string";
import FirebaseHelper from "../../FirebaseHelper";
import Event from "./Event";
import { withRouter } from "react-router-dom";
import TopBar from "../../components/TopBar/TopBar";
import moment from "moment";
import { MdKeyboardArrowRight } from "react-icons/md";
import Footer from "./Footer";
import LocalizationManager from "../../LocalizationManager";

class EventWrapper extends React.PureComponent {
  state = {
    loadingEvent: true,
    selectedSubEvent: null,
  };

  componentDidMount = () => {
    this._getEvent();
    const eventKey = this.props.location.pathname.replace("/", "");
    FirebaseHelper.onEventChanged(eventKey, this.onEventChanged);
  };

  componentWillUnmount = () => {
    const eventKey = this.props.location.pathname.replace("/", "");
    FirebaseHelper.offEventChanged(eventKey, this.onEventChanged);
  };

  onEventChanged = (snapshot) => {
    const event = snapshot.val() || {};
    let selectedSubEvent = null;
    if (event.sub_events) {
      selectedSubEvent = event.sub_events.find(
        (e) => e.key === this._getSelectedSubEvent()
      );
    }
    this.setState({ event, selectedSubEvent });
  };

  _getEvent = async () => {
    const eventKey = this.props.location.pathname.replace("/", "");
    const event = await FirebaseHelper.getEvent(eventKey);
    let selectedSubEvent = null;
    if (event.sub_events) {
      selectedSubEvent = event.sub_events.find(
        (e) => e.key === this._getSelectedSubEvent()
      );
      if (!selectedSubEvent) {
        FirebaseHelper.logOut();
      }
    }
    this.setState({ event, selectedSubEvent, loadingEvent: false });
  };

  _getSelectedSubEvent = () => {
    const params = queryString.parse(this.props.location.search);
    const event = params?.event;
    return event;
  };

  _setSelectedSubEvent = (selectedSubEvent) => {
    const params = queryString.parse(this.props.location.search);
    const code = params?.code;
    let codeString = "";
    if (code) {
      codeString = `&code=${code}`;
    }
    this.props.history.push(`?event=${selectedSubEvent.key}${codeString}`);
    this.setState({ selectedSubEvent });
  };

  resetSubEvent = () => {
    FirebaseHelper.logOut();
    this.setState({ selectedSubEvent: null });
  };

  render() {
    const { loadingEvent, event, selectedSubEvent } = this.state;
    if (loadingEvent) {
      return (
        <div className="loading">
          <PropagateLoader loading={true} color="#FF871A" margin={20} />
        </div>
      );
    }
    if (event.sub_events) {
      if (selectedSubEvent) {
        return (
          <Event
            resetSubEvent={this.resetSubEvent}
            event={{ ...event, ...selectedSubEvent }}
          />
        );
      }
      return (
        <div className="root-content">
          <div className="content-wrapper">
            <div className="top-content">
              <TopBar
                event={event}
                size={event.logo_size || 40}
                logo={event.logo_url}
              />
            </div>
            <div className="sub-event-content">
              <div className="titles">
                <h1>{event.name}</h1>
                <h2>
                  {LocalizationManager.localize(
                    "Tämä tapahtuma pitää sisällään useamman tapahtuman. Valitse alla olevista se, jota haluat katsoa.",
                    event
                  )}
                </h2>
              </div>
              {event.sub_events.map((se, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => this._setSelectedSubEvent(se)}
                    className="sub-event-row"
                  >
                    <div className="sub-titles">
                      <h3>{se.name}</h3>
                      <p>
                        {event.language == "en"
                          ? `Event begins ${moment(
                              se.date || event.date
                            ).format("DD.MM.YYYY")} at ${se.time || event.time}`
                          : `Tapahtuma alkaa ${moment(
                              se.date || event.date
                            ).format("DD.MM.YYYY")} klo ${
                              se.time || event.time
                            }`}
                      </p>
                    </div>
                    <MdKeyboardArrowRight />
                  </div>
                );
              })}
            </div>
          </div>
          <Footer event={this.state.event} />
        </div>
      );
    }

    return <Event event={event} />;
  }
}

export default withRouter(EventWrapper);
