import React from "react";
import moment from "moment-timezone";
import YouTube from "react-youtube";
import "./DemoEvent.scss";
import TopBar from "../../components/TopBar/TopBar";
import ArtistInfo from "../../components/ArtistInfo/ArtistInfo";
import EventTabs from "../Event/EventTabs";
import Chat from "../../components/Chat/Chat";
import ReactionCounter from "../../components/ReactionCounter/ReactionCounter";
import { MdChat } from "react-icons/md";
import FirebaseHelper from "../../FirebaseHelper";

class DemoEvent extends React.PureComponent {
  constructor(props) {
    super(props);
    this._mobileChat = React.createRef();

    this.event = {
      key: "demo",
      date: "2020-09-09",
      time: "13:00",
      artist: "Virtual Event Studio Demo",
      yt_id: "test",
      vimeo: "https://player.vimeo.com/video/456056483?autoplay=1&muted=0",
      noDownload: true,
      timetable: (
        <>
          <h3>18:00</h3>
          <p>Lorem ipsum dolor sit amet</p>
          <h3>18:30</h3>
          <p>Ut enim ad minim veniam</p>
          <h3>18:45</h3>
          <p>Duis aute irure dolor in reprehenderit</p>
          <h3>19:30</h3>
          <p>Sed ut perspiciatis unde omnis iste natus</p>
          <h3>20:30</h3>
          <p>Quis autem vel eum iure reprehenderit qui</p>
          <h3>21:00</h3>
          <p>Ut enim ad minima veniam</p>
        </>
      ),
      desc: (
        <>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <p>
            "Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            quae ab illo inventore veritatis et quasi architecto beatae vitae
            dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
            aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
            eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est,
            qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit,
            sed quia non numquam eius modi tempora incidunt ut labore et dolore
            magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis
            nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut
            aliquid ex ea commodi consequatur? Quis autem vel eum iure
            reprehenderit qui in ea voluptate velit esse quam nihil molestiae
            consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla
            pariatur?"
          </p>
        </>
      ),
    };

    this.state = {
      width: window.innerWidth,
      messages: [],
      reactions: {
        heart: 0,
        fire: 0,
        clap: 0,
      },
      loading: true,
      loadingReactions: true,
    };
  }

  componentDidMount = () => {
    window.addEventListener("resize", this._handleResize);
    if (!this.props.noRequests) {
      this._getMessages();
      this._getReactions();
    }
  };

  _getReactions = async () => {
    const reactions = await FirebaseHelper.getReactions(this.event);
    this.setState({ reactions, loadingReactions: false });
    FirebaseHelper.onReaction(this.event, this._onReaction);
  };

  _onReaction = (snapshot) => {
    const reactions = snapshot.val() || {};
    this.setState({ reactions });
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this._handleResize);
    FirebaseHelper.removeMsgListener(this.event, this._onMessage);
    FirebaseHelper.removeReactionListener(this.event, this._onReaction);
  };

  _getMessages = async () => {
    if (!this.event.yt_id) return;
    FirebaseHelper.onMessage(this.event, this._onMessage, true);
    const messages = await FirebaseHelper.getMessages(this.event, true);
    this.setState({ messages, loading: false });
  };

  _onMessage = (snapshot) => {
    const messages = Object.values(snapshot.val() || {});
    this.setState({ messages });
  };

  scrollToSupport = () => {
    if (!this._showSupportTitle.current) {
      return;
    }
    window.scrollTo({
      top: this._showSupportTitle.current.offsetTop,
      left: 0,
      behavior: "smooth",
    });
    setTimeout(() => {
      this._showSupport.current && this._showSupport.current.flash();
    }, 500);
  };

  _handleResize = () => {
    let showChat = this.state.showChat;
    if (this._mobileChat.current) {
      this._mobileChat.current.clearLayoutCache();
    }
    if (this.state.width < 1200 && window.innerWidth >= 1200) {
      showChat = false;
    }
    this.setState({
      width: window.innerWidth,
      showChat,
    });
  };

  _getDateString = () => {
    const date = moment(
      this.event.date + " " + this.event.time,
      "YYYY-MM-DD HH:mm"
    );
    const now = moment();
    const diff = date.diff(now, "minutes");
    const days = Math.floor(diff / 1440);
    const hours = Math.floor((diff % 1440) / 60);
    const minutes = diff % 60;
    if (days > 0) {
      if (hours > 0) {
        if (minutes > 0) {
          return `Tapahtuma alkaa ${days} päivän, ${hours} tunnin ja ${minutes} minuutin kuluttua.`;
        } else {
          return `Tapahtuma alkaa ${days} päivän, ${hours} tunnin kuluttua.`;
        }
      } else if (minutes > 0) {
        return `Tapahtuma alkaa ${days} päivän ja ${minutes} minuutin kuluttua.`;
      } else {
        return `Tapahtuma alkaa ${days} päivän kuluttua.`;
      }
    } else if (hours > 0) {
      if (minutes > 0) {
        return `Tapahtuma alkaa ${hours} tunnin ja ${minutes} minuutin kuluttua.`;
      } else {
        return `Tapahtuma alkaa ${hours} tunnin kuluttua.`;
      }
    } else {
      return `Tapahtuma alkaa ${minutes} minuutin kuluttua.`;
    }
  };

  _renderStream = () => {
    const { event } = this;
    return (
      <div className="stream-container">
        <div className="row">
          {event.yt_id && (
            <div className="stream">
              {!this.props.noRequests && event.vimeo ? (
                <iframe
                  title="Palatsi Live Stream"
                  src={event.vimeo}
                  mozallowfullscreen="true"
                  webkitallowfullscreen="true"
                  allowFullScreen={true}
                  allow="fullscreen; autoplay"
                  frameBorder="0"
                />
              ) : (
                <YouTube
                  videoId={event.yt_id}
                  opts={{
                    playerVars: {
                      autoplay: 1,
                    },
                  }}
                />
              )}
            </div>
          )}
          {event.yt_id && (
            <div className="chat">
              {this.state.width >= 1200 ? (
                <Chat
                  scrollToSupport={this.scrollToSupport}
                  messages={this.state.messages}
                  loading={this.state.loading}
                  event={this.event}
                />
              ) : null}
            </div>
          )}
        </div>
      </div>
    );
  };

  _close = () => {
    const { handleClose } = this.props;
    handleClose();
  };

  _renderTopContent = () => {
    return (
      <React.Fragment>
        <TopBar
          noRequests={this.props.noRequests}
          onClose={this._close}
          logo={require("../../assets/ves_logo_dark.svg")}
        />
        {this.event.yt_id ? this._renderStream() : this._renderPreview()}
      </React.Fragment>
    );
  };

  _renderPreview = () => {
    const { event } = this;
    return (
      <div className="preview">
        <div className="img">
          {!this.props.noRequests && (
            <img alt="Artist promo" src={event.photo} />
          )}
        </div>
        <div className="texts">
          <h2>{event.artist}</h2>
          <p>{this._getDateString()}</p>
        </div>
      </div>
    );
  };

  _toggleChat = () => {
    this.setState({ showChat: !this.state.showChat });
  };

  render() {
    const { event } = this;
    return (
      <div
        style={{ overflow: this.state.showChat ? "hidden" : "auto" }}
        id="event"
      >
        <div className="main-content">
          <div className="top-content">{this._renderTopContent()}</div>
          {event.yt_id && (
            <ReactionCounter
              loading={this.state.loadingReactions}
              reactions={this.state.reactions}
              event={event}
            />
          )}
          <div ref={this._showSupportTitle} className="bottom-content">
            <div className="wide">
              <ArtistInfo event={event} />
            </div>
            <div className="narrow">
              <EventTabs event={this.event} />
            </div>
          </div>
        </div>
        {this.state.width < 1200 && event.yt_id && (
          <>
            <div
              style={{
                top: this.state.showChat ? 0 : "100%",
                left: 0,
                right: 0,
                height: "100%",
              }}
              className="chat-overlay"
            >
              <Chat
                ref={this._mobileChat}
                messages={this.state.messages}
                loading={this.state.loading}
                toggleChat={this._toggleChat}
                showTopBar
                event={event}
                width={this.state.width}
                loadingReactions={this.state.loadingReactions}
                reactions={this.state.reactions}
              />
            </div>
            <div onClick={this._toggleChat} className="chat-btn">
              <MdChat className="icon" />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default DemoEvent;
