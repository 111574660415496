import React from "react";
import Confetti from "react-dom-confetti";
import "./ThankYou.scss";

class ThankYou extends React.PureComponent {
  state = {
    confetti: false,
  };

  confettiConfig = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 3500,
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  componentDidMount = () => {
    this.setState({ confetti: true });
  };

  render() {
    const { confetti } = this.state;
    return (
      <div id="thank-you">
        <Confetti
          className="confetti"
          active={confetti}
          config={this.confettiConfig}
        />
        <img alt="" src={require("../assets/ves_logo_dark.svg")} />
        <h1>
          Maksu vastaanotettu, kiitos tilauksestasi!{" "}
          <span role="img" aria-labelledby="emoji">
            🎉
          </span>
        </h1>
        <h2>
          Pääsykoodi ja kuitti on lähetetty sähköpostiosoitteeseesi! Voit nyt
          sulkea välilehden.
        </h2>
        <hr />
        <div>
          <p>
            Ongelmatapauksissa luethan{" "}
            <a href="/faq" target="_blank" rel="noopener noreferrer">
              usein kysytyt kysymykset
            </a>
            .
          </p>
          <p>
            Jos ongelmaan ei löydy ratkaisua, ota yhteyttä osoitteeseen{" "}
            <a href="mailto:support@virtualeventstudio.fi">
              support@virtualeventstudio.fi
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default ThankYou;
