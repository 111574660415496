export const colors = {
  bg: "#131a22",
  dark: "#060b12",
  light: "#FFD48D"
}

export const forbiddenWords = [
  "anaali",
  "fag",
  "anal",
  "helvetti",
  "helvetin",
  "hoe",
  "cepi",
  "homo",
  "hotero",
  "cock",
  "horo",
  "jutku",
  "cunt",
  "huora",
  "kehari",
  "epäsikiö",
  "kyrpä",
  "kulli",
  "fuck",
  "lesbo",
  "kusipää",
  "fägy",
  "neekeri",
  "lortto",
  "fägäri",
  "paska",
  "lumppu",
  "gay",
  "perkele",
  "lutka",
  "geippari",
  "perse",
  "läski",
  "gigolo",
  "raiskari",
  "mulkku",
  "goatse",
  "retardi",
  "mulkvisti",
  "hintti",
  "ryssä",
  "mutakuono",
  "hooker",
  "saatana",
  "narkki",
  "horatsu",
  "suvakki",
  "nekru",
  "lesbo",
  "lesbian",
  "tuhkamuna",
  "pedari",
  "nigga",
  "vajakki",
  "perseenreikä",
  "nigger",
  "vamppi",
  "pillu",
  "nussi",
  "viddu",
  "porkkanapillu",
  "pussy",
  "vittu",
  "runkkari",
  "retard",
  "vitun",
  "vammanen",
  "runkku",
  "vulva",
  "shit",
  "wittu",
  "wänkkeri",
  "ämmä",
  "äpärä",
];
