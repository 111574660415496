import React from "react";
import styles from "./PrivacyPolicy.module.scss"

class PrivacyPolicy extends React.PureComponent {
  render() {
    return (
      <div id={styles.policyPage}>
        <img alt="" src={require("../assets/ves_logo_dark.svg")} />
        <h1>Tietosuojaseloste</h1>
        <p>
          Virtual Event Studio Oy kerää kävijäseurantatietoja tapahtuman
          kävijöistä sekä rajoitetusti asiakkaiden yhteystietoja maksupalvelun
          osalta. Yritys on sitoutunut noudattamaan henkilötietojen käsittelyssä
          tietosuojalainsäädännön vaatimuksia.
        </p>
        <h2>Mitä tietoa keräämme?</h2>
        <h3>Evästeet</h3>
        <p>
          Kävijäseurantatietojen käsittelyn tarkoitus on sivuston ylläpidon ja
          kehittämisen mahdollistaminen, sekä yleisellä tasolla tapahtuman
          järjestäjän informoiminen kävijämääristä. Sivujen käytöstä
          tilastoidaan muun muassa käyttäjämäärä, käyttömaa, käyttöaika ja
          käytetty selain sekä sisällöt, joissa kävijä on vieraillut. Näiden
          tietojen avulla yksittäinen käyttäjä ei ole yksilöitävissä.
        </p>
        <p>Evästeitä käytetään myös palveluun kirjautumisen toteuttamiseen.</p>
        <h3>Lippupalvelun tiedot</h3>
        <p>
          Tallennamme suojattuun tietokantaan tiedot tapahtumalipun ostaneista.
          Tietokantaan tallennetaan asiakkaan sähköpostiosoite, ostetut liput
          sekä aikakoodi. Näitä tietoja käytetään vain ja ainoastaan
          verkkoalustan tekniseen toimintaan ja mahdollisiin
          asiakaspalvelutilanteisiin. Maksupalvelu Checkout tallentaa
          maksutiedot omien sopimusehtojensa mukaisesti.
        </p>
        <h2>Miten käytämme keräämäämme tietoa?</h2>
        <p>
          Kävijätietojen analysointiin käytetään Google Analyticsia. Lisätietoa
          Google Analyticsista löydät osoitteesta{" "}
          <a
            href="https://www.google.com/analytics/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/analytics/
          </a>
          . Voit estää kävijätietojen seurannan lataamalla seuraavan lisäosan
          selaimeesi{" "}
          <a
            href="https://tools.google.com/dlpage/gaoptout"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://tools.google.com/dlpage/gaoptout
          </a>
          . Palvelumme sisältää upotettua sisältöä (videot). Toisilta sivuilta
          upotetun sisällön avaaminen on verrattavissa siihen, että kävijä
          vierailisi itse kolmannen osapuolen sivustolla. Sivustot voivat kerätä
          tietoa sinusta, käyttää evästeitä, upottaa kolmannen osapuolen
          seurantaevästeitä ja monitoroida vuorovaikutustasi upotetun sisällön
          kanssa. Sivustoomme upotettuihin kolmannen osapuolen sisältöihin
          sovelletaan palveluiden omia käyttöehtoja.
        </p>
        <p>
          Tietokantaan tallennettua tapahtuman lippudataa käytetään ainoastaan
          verkkoalustan tekniseen toimintaan ja mahdollisiin
          asiakaspalvelutilanteisiin.
        </p>
        <h2>Luovutammeko kävijätietoja?</h2>
        <p>
          Virtual Event Studion kävijäseurantatietoja käytetään
          kokonaisuudessaan vain sivuston kehittämiseen ja ylläpitoon yrityksen
          sisäisesti. Virtual Event Studio luovuttaa kävijäseurantatietoja
          kolmansille osapuolille ainoastaan seuraavissa tilanteissa:
        </p>
        <h3>Tapahtuman järjestäjät</h3>
        <p>
          Kävijäseurantatietoa jaetaan tapahtuman järjestäjälle yleisellä
          tasolla. Tiedot eivät ole yksilöitävissä yksittäiseen henkilöön.
          Koostettua tietoa on muun muassa käyttäjämäärä, käyttömaa, käyttöaika
          ja käytetty selain sekä sisällöt, joissa kävijät ovat vierailleet.
        </p>
        <h3>Oikeudelliset syyt</h3>
        <p>
          Virtual Event Studio voi jakaa kävijäseurantatietoa kolmansien
          osapuolten kanssa, mikäli pääsy henkilötietoihin tai niiden muu
          käsittely on tarpeen 1) soveltuvan lainsäädännön ja/tai oikeuden
          määräyksen täyttämiseksi; 2) väärinkäytöksen, turvallisuusriskin tai
          teknisen ongelman havaitsemiseen, estämiseen tai käsittelemiseen.
        </p>
        <h2>Tietojen suojaus</h2>
        <p>
          Tietoa kerätään tietokantoihin, jotka sijaitsevat lukituissa ja
          vartioiduissa tiloissa. Tietoihin pääsevät käsiksi vain niihin
          oikeutetut ennalta määrätyt henkilöt.
        </p>
        <h2>Tietojen säilytys</h2>
        <p>
          Google Analytics säilyttää kävijäseurantatietoja 14 kuukautta.
          Tapahtumaostojen tiedot poistetaan tietokannasta tapahtuman
          katseluajan loputtua. Aika on tapahtumakohtainen.
        </p>
        <h2>Käyttäjän oikeudet</h2>
        <p>
          Käyttäjällä on oikeus vaatia väärän, vanhentuneen tai tarpeettoman
          tiedon korjaamista. Voit pyytää myös omia tietojasi poistettavaksi
          rekisteristä, mikäli sille ei ole oikeudellista estettä. Voit pyytää
          omien tietojesi tarkistamista ottamalla meihin yhteyttä (
          <a href="mailto:support@virtualeventstudio.fi">
            support@virtualeventstudio.fi
          </a>
          ). Käyttäjällä on myös oikeus saattaa tietosuojaan liittyvä toiminnan
          lainmukaisuus Tietosuojavaltuutetun toimiston arvioitavaksi
          (tietosuoja ät om.fi)
        </p>
        <h2>Tietosuojaselosteen muuttaminen</h2>
        <p>
          Virtual Event Studio voi muuttaa selostetta. Selosteen muutoksesta
          ilmoitetaan sivustolla.
        </p>
        <h2>Kuka rekisteriä pitää ja mihin voin ottaa yhteyttä?</h2>
        <p>Rekisterinpitäjä on:</p>
        <p>Virtual Event Studio Oy</p>
        <p>Y-tunnus: 3169244-1</p>
        <a href="mailto:support@virtualeventstudio.fi">
          support@virtualeventstudio.fi
        </a>
        <p>
          Vastaamme tätä rekisteriä koskeviin kysymyksiin ja palautteisiin
          mahdollisimman pian.
        </p>
      </div>
    );
  }
}

export default PrivacyPolicy;
