import React from "react";
import FirebaseHelper from "../../FirebaseHelper";
import moment from "moment";
import styles from "./Registrations.module.scss";
import LocalizationManager from "../../LocalizationManager";
import { split } from "lodash";

class Registrations extends React.PureComponent {
  state = {
    registrations: [],
  };

  answerMap = {
    1: "Valmistamaan Reilun kaupan tuotteita",
    2: "Maahantuomaan uusia Reilun kaupan tuotteita",
    3: "Myymään Reilun kaupan tuotteita myymälässä/kahvilassa/ravintolassa",
    4: "Olen kiinnostunut eri mahdollisuuksista Reilun kaupan kanssa",
    5: "Yritystoimintamme ihmisoikeusriskien kartoittamisesta ja vastuullisuuden parantamisesta Reilun kaupan kanssa",
  };

  componentDidMount = () => {
    this._getRegistrations();
  };

  _getRegistrations = () => {
    const { event } = this.props;
    FirebaseHelper.getRegistrations(event).then((registrations) => {
      const sortedRegistrations = registrations.sort((a, b) => {
        const aTimestamp = a.timestamp && moment(a.timestamp);
        const bTimestamp = b.timestamp && moment(b.timestamp);
        if (!aTimestamp && bTimestamp) return 1;
        if (!bTimestamp && aTimestamp) return -1;
        if (!aTimestamp && !bTimestamp) return 0;
        if (aTimestamp.isBefore(bTimestamp)) {
          return 1;
        } else if (aTimestamp.isAfter(bTimestamp)) {
          return -1;
        } else {
          return 0;
        }
      });
      this.setState({ registrations: sortedRegistrations });
    });
  };

  getCheckboxValues = (registration) => {
    return split(registration.checkboxData || "", ",").map((a) => {
      if (a == 6) {
        return (
          <li key={a}>
            <p>{registration.customCheckboxValue || "-"}</p>
          </li>
        );
      }
      return (
        <li key={a}>
          <p>{this.answerMap[a] || "-"}</p>
        </li>
      );
    });
  };

  render() {
    const { event } = this.props;
    const { registrations } = this.state;
    const inPerson = registrations.filter((r) => r.in_person == 1);
    const virtual = registrations.filter((r) => r.in_person != 1);
    return (
      <div id={styles.registrations}>
        <div className={styles.titleRow}>
          <div>
            <h2>
              {event.language == "en"
                ? `Registrations${
                    event.sub_events ? " (Only this sub event)" : ""
                  }`
                : `Ilmoittautumiset${
                    event.sub_events ? " (Vain tämä alitapahtuma)" : ""
                  }`}
            </h2>
            <p>{`Ilmoittautumisia yhteensä ${registrations.length} kpl`}</p>
          </div>
          <button onClick={this._getRegistrations}>
            {LocalizationManager.localize("Päivitä lista", event)}
          </button>
        </div>
        <div className={styles.registrations}>
          <h2>Paikan päällä</h2>
          <p>Ilmoittautumisia {inPerson.length} kpl</p>
          {inPerson.map((registration, i) => {
            return (
              <div key={i} className={styles.row}>
                <div>
                  <p>
                    <strong>Osallistuu:</strong>{" "}
                    {registration.in_person == 1
                      ? "Paikan päällä"
                      : "Virtuaalisesti"}
                  </p>
                  <p>
                    <strong>Nimi:</strong> {registration.name || "-"}
                  </p>
                  <p>
                    <strong>Titteli:</strong> {registration.title || "-"}
                  </p>
                  <p>
                    <strong>Yritys/yhteisö:</strong>{" "}
                    {registration.company || "-"}
                  </p>
                  <p>
                    <strong>Sähköpostiosoite:</strong>{" "}
                    {registration.email || "-"}
                  </p>
                  <p>
                    <strong>Puhelinnumero:</strong> {registration.phone || "-"}
                  </p>
                  {/* <p>
                    <strong>Olen kiinnostunut:</strong>
                  </p>
                  <ul>{this.getCheckboxValues(registration)}</ul> */}
                  <p>
                    <strong>
                      Mitä haluaisit tietää Reilun kaupan toiminnasta Suomessa
                      tai kansainvälisesti?:
                    </strong>{" "}
                    {registration.custom1 || "-"}
                  </p>
                </div>
              </div>
            );
          })}
          <h2>Virtuaalisesti</h2>
          <p>Ilmoittautumisia {virtual.length} kpl</p>
          {virtual.map((registration, i) => {
            return (
              <div key={i} className={styles.row}>
                <div>
                  <p>
                    <strong>Osallistuu:</strong>{" "}
                    {registration.in_person == 1
                      ? "Paikan päällä"
                      : "Virtuaalisesti"}
                  </p>
                  <p>
                    <strong>Nimi:</strong> {registration.name || "-"}
                  </p>
                  <p>
                    <strong>Titteli:</strong> {registration.title || "-"}
                  </p>
                  <p>
                    <strong>Yritys/yhteisö:</strong>{" "}
                    {registration.company || "-"}
                  </p>
                  <p>
                    <strong>Sähköpostiosoite:</strong>{" "}
                    {registration.email || "-"}
                  </p>
                  <p>
                    <strong>Puhelinnumero:</strong> {registration.phone || "-"}
                  </p>
                  {/* <p>
                    <strong>Olen kiinnostunut:</strong>
                  </p>
                  <ul>{this.getCheckboxValues(registration)}</ul> */}
                  <p>
                    <strong>
                      Mitä haluaisit tietää Reilun kaupan toiminnasta Suomessa
                      tai kansainvälisesti?:
                    </strong>{" "}
                    {registration.custom1 || "-"}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Registrations;
