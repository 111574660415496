import React from "react";
import "./ArtistInfo.scss";
import moment from "moment";
import LocalizationManager from "../../LocalizationManager";

const ArtistInfo = (props) => {
  const { event } = props;
  const christmasMode = false;
  return (
    <div id="artist-info">
      {christmasMode && (
        <>
          <img
            className="tree"
            alt="Tree"
            src={require("../../assets/tree.png")}
          />
          <img
            className="kapy1"
            alt="Käpy 1"
            src={require("../../assets/kapy1.png")}
          />
          <img
            className="kapy2"
            alt="Käpy 2"
            src={require("../../assets/kapy2.png")}
          />
        </>
      )}
      <div className="top-title">
        <h2 className={event.key == "reilukauppa" && "helvetica"}>
          {event.name}
        </h2>
        <p className={event.key == "reilukauppa" && "helvetica"}>{`${
          event.date
            ? moment(event.date, "YYYY-MM-DD").format("DD.MM.YYYY")
            : ""
        } ${event.time ? event.time : ""}`}</p>
        {event.description_img_url && (
          <img
            className="desc-img"
            src={event.description_img_url}
            alt="Event"
          />
        )}
        {event.description && (
          <div
            className={`description ${
              event.key == "reilukauppa" && "helvetica"
            }`}
            dangerouslySetInnerHTML={{ __html: event.description }}
          />
        )}
      </div>
      {(event.schedule ||
        event.schedule_url ||
        event.schedule_download_url) && (
        <div className="desc-container">
          <div className={`desc ${event.key == "reilukauppa" ? "blue" : ""}`}>
            <h2>
              {event.schedule_title ||
                LocalizationManager.localize("Aikataulu:", event)}
            </h2>
            {event.schedule_url ? (
              <a
                href={event.schedule_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {LocalizationManager.localize("Avaa ohjelma tästä", event)}
              </a>
            ) : (
              <>
                <div className="link">
                  {event.schedule_download_url &&
                    event.schedule_download_url !== "" && (
                      <a href={event.schedule_download_url} download>
                        {LocalizationManager.localize(
                          "Lataa ohjelma tästä",
                          event
                        )}
                      </a>
                    )}
                </div>
                {event.schedule &&
                  Object.keys(event.schedule).map((time, i) => {
                    return (
                      <div key={i}>
                        <h3>
                          {time.length === 1 || time.length === 2
                            ? `${time}.`
                            : time}
                        </h3>
                        <p>{event.schedule[time]}</p>
                      </div>
                    );
                  })}
              </>
            )}
            {christmasMode && (
              <img
                alt="Event logo"
                src={
                  event.desc_logo_url ||
                  require("../../assets/ves_logo_dark.svg")
                }
              />
            )}
          </div>
          {christmasMode && (
            <img
              className="plant"
              alt="Plant"
              src={require("../../assets/plant.png")}
            />
          )}
        </div>
      )}
      {event.styleTemplate == "jkl" && false && (
        <div className="desc-container image-container">
          <img src={require('../../assets/jklkesa-2022-juliste.jpg')} />
        </div>
      )}
      {christmasMode && (
        <img
          className="branch"
          alt="Branch"
          src={require("../../assets/branch.png")}
        />
      )}
    </div>
  );
};

export default ArtistInfo;
