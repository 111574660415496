import React from "react";
import "./ReactionCounter.scss";

export default class ReactionCounter extends React.PureComponent {
  state = {
    reactions: this.props.reactions,
    styles: {
      heart: null,
      fire: null,
      clap: null,
    },
  };

  first = true;

  componentWillReceiveProps = (newProps) => {
    if (newProps.reactions !== this.props.reactions) {
      const oldReactions = this.state.reactions;
      const styles = { ...this.state.styles };
      if (!this.first) {
        Object.keys(newProps.reactions).forEach((reaction) => {
          if (newProps.reactions[reaction] > oldReactions[reaction]) {
            styles[reaction] = { transform: "scale(1.4)" };
          }
        });
      } else {
        this.first = false;
      }
      this.setState({
        reactions: newProps.reactions,
        styles
      }, () => {
        setTimeout(() => {
          this.setState({
            styles: {
              heart: null,
              fire: null,
              clap: null,
            },
          });
        }, 300);
      });
    }
  };

  render() {
    const {
      reactions: { heart, fire, clap },
      styles,
    } = this.state;
    const { loading } = this.props;
    return (
      <div id="reaction-counter">
        {loading ? (
          <p className="loading-chat">Ladataan...</p>
        ) : (
          <>
            <div style={styles.fire} className="reaction">
              <span role="img" aria-label="Fire emoji" className="emoji">
                🔥
              </span>
              <p>{fire || 0}</p>
            </div>
            <div style={styles.heart} className="reaction">
              <span role="img" aria-label="Heart emoji" className="emoji">
                ❤️
              </span>
              <p>{heart || 0}</p>
            </div>
            <div style={styles.clap} className="reaction">
              <span role="img" aria-label="Clap emoji" className="emoji">
                👏
              </span>
              <p>{clap || 0}</p>
            </div>
          </>
        )}
      </div>
    );
  }
}
