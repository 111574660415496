import React from "react";
import styles from "./Codes.module.scss";
import {
  DataGrid,
  GridCloseIcon,
  GridSearchIcon,
} from "@material-ui/data-grid";
import { MdContentCopy, MdDelete } from "react-icons/md";
import moment from "moment";
import FirebaseHelper from "../../FirebaseHelper";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  InputAdornment,
  InputBase,
  TextField,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import { withSnackbar } from "notistack";

const muiStyles = (theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
});

class Codes extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      codes: [],
      filteredCodes: [],
      orders: [],
      createCodesMode: false,
      selectedEvent: props.event.key,
      newCodeData: {},
      searchValue: "",
      shownOrder: undefined,
      confirmDialogRow: undefined,
    };
  }

  orderSliderColumns = [
    { field: "name", headerName: "Nimi", width: 260 },
    {
      field: "quantity",
      headerName: "Lippujen määrä",
      width: 180,
    },
    {
      field: "price",
      headerName: "Hinta/lippu",
      width: 160,
      valueGetter: ({ row }) => {
        return `${row.price} €`;
      },
    },
    {
      field: "total",
      headerName: "Hinta yht.",
      width: 160,
      valueGetter: ({ row }) => {
        return `${Number(row.price) * Number(row.quantity)} €`;
      },
    },
  ];

  columns = [
    {
      field: "actions",
      headerName: "Toiminnot",
      width: 150,
      renderCell: ({ row }) => {
        return (
          <div className={styles.actionCell}>
            <Tooltip title="Poista koodi">
              <button
                onClick={() => {
                  this.showConfirmDialog(row);
                }}
              >
                <MdDelete />
              </button>
            </Tooltip>
            <Tooltip title="Kopio katselulinkki leikepöydälle">
              <button
                onClick={() => {
                  this.copyLinkToClipboard(row);
                }}
              >
                <MdContentCopy />
              </button>
            </Tooltip>
          </div>
        );
      },
    },
    { field: "passcode", headerName: "Pääsykoodi", width: 150 },
    {
      field: "amount",
      headerName: "Lippujen määrä",
      width: 190,
    },
    { field: "used", headerName: "Käytetty", width: 150, editable: true },
    {
      field: "admin",
      headerName: "Admin",
      width: 120,
      valueGetter: ({ row }) => {
        return row.admin ? "Kyllä" : "Ei";
      },
    },
    { field: "email", headerName: "Sähköposti", width: 220 },
    {
      field: "sessions_per_ticket",
      headerName: "Sessiot per lippu",
      placeholder: "lol",
      width: 200,
    },
    {
      field: "timestamp",
      headerName: "Luotu",
      width: 180,
      type: "date",
    },
    {
      field: "transactionId",
      headerName: "Transaktion id",
      width: 300,
      valueGetter: ({ row }) => {
        return row.transactionData?.id || "-";
      },
    },
    {
      field: "totalPrice",
      headerName: "Tilauksen arvo",
      width: 300,
      renderCell: ({ row }) => {
        return (
          <div className={styles.orderCell}>
            {this.getTotalPrice(row.transactionData?.ticketData)}
            {row.transactionData && (
              <button
                onClick={(e) => {
                  this.showOrder(row);
                }}
              >
                Näytä koko tilaus
              </button>
            )}
          </div>
        );
      },
    },
  ];

  removeCode = (row) => {
    const { id } = row;
    FirebaseHelper.removeCode({ key: this.state.selectedEvent }, id);
    this.closeConfirmDialog();
  };

  copyLinkToClipboard = (row) => {
    const { passcode } = row;
    const { event } = this.props;
    const { selectedEvent } = this.state;
    let link = `https://live.virtualeventstudio.fi/${this.state.selectedEvent}?code=${passcode}`;
    if (event.sub_events) {
      const index = event.sub_events.findIndex((e) => e.key == selectedEvent);
      if (index != -1) {
        const mainEventKey = event.key.split("_")[0];
        link = `https://live.virtualeventstudio.fi/${mainEventKey}?event=${selectedEvent}&code=${passcode}`;
      }
    }
    try {
      navigator.clipboard.writeText(link);
      this.props.enqueueSnackbar("Katselulinkki kopioitu leikepöydälle!", {
        variant: "success",
      });
    } catch (e) {
      console.error(e);
    }
  };

  copyAllLinksToClipboard = () => {
    const { event } = this.props;
    const { selectedEvent, codes } = this.state;
    let link = "";
    let linkPrefix = `https://live.virtualeventstudio.fi/${this.state.selectedEvent}?`;
    if (event.sub_events) {
      const index = event.sub_events.findIndex((e) => e.key == selectedEvent);
      if (index != -1) {
        const mainEventKey = event.key.split("_")[0];
        linkPrefix = `https://live.virtualeventstudio.fi/${mainEventKey}?event=${selectedEvent}&`;
      }
    }

    (codes || []).forEach((code) => {
      const passcode = code.passcode;
      link += `${linkPrefix}code=${passcode}\n`;
    });

    try {
      navigator.clipboard.writeText(link);
      this.props.enqueueSnackbar("Katselulinkit kopioitu leikepöydälle!", {
        variant: "success",
      });
    } catch (e) {
      console.error(e);
    }
  };

  showOrder = (row) => {
    this.setState({ shownOrder: row });
  };

  hideOrder = () => {
    this.setState({ shownOrder: undefined });
  };

  getTotalPrice = (ticketData, onlyNumber = false) => {
    let total = 0;
    if (!Array.isArray(ticketData) && !!ticketData) {
      if (ticketData.price == undefined) {
        Object.values(ticketData).forEach((ticket) => {
          total += Number(ticket.quantity || "0") * Number(ticket.price || "0");
        });
      } else {
        total =
          Number(ticketData.quantity || "0") * Number(ticketData.price || "0");
      }
    } else {
      total = (ticketData || []).reduce((total, ticket) => {
        total += Number(ticket.quantity || "0") * Number(ticket.price || "0");
        return total;
      }, 0);
    }
    return onlyNumber ? total : `${total > 0 ? total : "-"} €`;
  };

  componentDidMount = () => {
    this.getCodes();
    FirebaseHelper.onCodesChanged(this.props.event, this.onCodesChanged);
  };

  componentWillUnmount = () => {
    FirebaseHelper.offCodesChanged(this.props.event, this.onCodesChanged);
  };

  onCodesChanged = (snapshot) => {
    const val = snapshot.val() || {};
    const codes = Object.keys(val).map((key) => {
      return {
        ...val[key],
        id: key,
      };
    });
    this.setState({ codes });
  };

  getCodes = () => {
    const { selectedEvent } = this.state;
    FirebaseHelper.getCodes({ key: selectedEvent }).then((codes) => {
      this.setState({ codes });
    });
  };

  onCellEdited = (params) => {
    const {
      id,
      field,
      props: { value },
    } = params;
    const { selectedEvent } = this.state;
    FirebaseHelper.editCode(id, { [field]: value }, selectedEvent);
  };

  generatePasscode = () => {
    if (this.passcode) return this.passcode;
    var length = 8,
      charset =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };

  createCodes = async () => {
    const { amount, sessions_per_ticket, admin } = this.state.newCodeData;
    const { selectedEvent } = this.state;
    const data = {
      timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
      amount: 1,
      email: "support@virtualeventstudio.fi",
    };
    if (sessions_per_ticket) data.sessions_per_ticket = sessions_per_ticket;
    if (admin) data.admin = admin;
    if (amount) {
      for (let i = 0; i < Number(amount); i++) {
        data.passcode = this.generatePasscode();
        FirebaseHelper.createCode(data, { key: selectedEvent });
      }
    } else {
      data.passcode = this.generatePasscode();
      FirebaseHelper.createCode(data, { key: selectedEvent });
    }
    this.setState({ createCodesMode: false, newCodeData: {} });
  };

  setCreateCodesMode = () => this.setState({ createCodesMode: true });
  cancelCreateCodesMode = () => this.setState({ createCodesMode: false });

  showConfirmDialog = (confirmDialogRow) => this.setState({ confirmDialogRow });
  closeConfirmDialog = () => this.setState({ confirmDialogRow: undefined });

  onInputValueChanged = (e) => {
    const { name, value } = e.target;
    const newCodeData = {
      ...this.state.newCodeData,
      [name]: value,
    };
    this.setState({ newCodeData });
  };

  setSearchValue = (e) => {
    this.setState({ searchValue: e.target.value }, () => {
      this.filterCodes();
    });
  };

  filterCodes = () => {
    const filteredCodes = (this.state.codes || []).filter((code) =>
      `${code.passcode} ${code.email} ${code.transactionData?.id}`
        .toLowerCase()
        .includes(this.state.searchValue.toLowerCase())
    );
    this.setState({ filteredCodes });
  };

  setSelectedEvent = (selectedEvent) => {
    this.setState({ selectedEvent }, () => {
      this.getCodes();
    });
  };

  render() {
    const {
      codes,
      filteredCodes,
      createCodesMode,
      searchValue,
      selectedEvent,
      shownOrder,
      confirmDialogRow,
      newCodeData: { amount, sessions_per_ticket, admin },
    } = this.state;
    const { classes, event } = this.props;
    return (
      <div id={styles.codes}>
        <div className={styles.top}>
          {event.sub_events && (
            <div className={styles.tabs}>
              {[
                { key: event.key.split("_")[0], name: "Päätapahtuma" },
                ...event.sub_events,
              ].map((e) => (
                <div
                  className={`${styles.tab} ${
                    selectedEvent == e.key ? styles.selected : ""
                  }`}
                  onClick={() => this.setSelectedEvent(e.key)}
                >
                  {e.name}
                </div>
              ))}
            </div>
          )}
          <div className={styles.search}>
            <InputBase
              startAdornment={
                <InputAdornment position="start">
                  <GridSearchIcon />
                </InputAdornment>
              }
              type="search"
              className={classes.input}
              placeholder="Etsi..."
              value={searchValue}
              onChange={this.setSearchValue}
            />
          </div>
          {!createCodesMode && (
            <div className={styles.actions}>
              <button onClick={this.setCreateCodesMode}>
                Luo uusia koodeja
              </button>
              <button onClick={this.copyAllLinksToClipboard}>
                Kopioi kaikki katselulinkit leikepöydälle
              </button>
            </div>
          )}
          {createCodesMode && (
            <div className={styles.createContainer}>
              <div className={styles.inputs}>
                <TextField
                  variant="outlined"
                  size="small"
                  label="Määrä"
                  type="number"
                  name="amount"
                  onChange={this.onInputValueChanged}
                  value={amount}
                />
                <TextField
                  variant="outlined"
                  size="small"
                  label="Sessiot per lippu"
                  type="number"
                  name="sessions_per_ticket"
                  onChange={this.onInputValueChanged}
                  value={sessions_per_ticket}
                />
                <div className={styles.checkbox}>
                  <Checkbox
                    onChange={(e) =>
                      this.onInputValueChanged({
                        target: { name: "admin", value: e.target.checked },
                      })
                    }
                    checked={admin}
                  />
                  <p>Adminkoodi</p>
                </div>
              </div>
              <div className={styles.buttons}>
                <button
                  className={styles.cancel}
                  onClick={this.cancelCreateCodesMode}
                >
                  Peruuta
                </button>
                <button onClick={this.createCodes}>Luo koodit</button>
              </div>
            </div>
          )}
        </div>
        <div className={styles.list}>
          <DataGrid
            rows={!!searchValue ? filteredCodes : codes}
            columns={this.columns}
            checkboxSelection={false}
            paginationMode="client"
            editMode="client"
            onEditCellChangeCommitted={this.onCellEdited}
            pageSize={100}
            disableSelectionOnClick
            rowsPerPageOptions={[]}
            sortModel={[
              {
                field: "timestamp",
                sort: "desc",
              },
            ]}
          />
        </div>
        <Drawer open={shownOrder} onClose={this.hideOrder} anchor="right">
          <div className={styles.orderSlider}>
            <div className={styles.sliderTop}>
              <h2>Tarkastele tilausta</h2>
              <button onClick={this.hideOrder}>
                <GridCloseIcon />
              </button>
            </div>
            {shownOrder && (
              <div className={styles.sliderContent}>
                <div className={styles.inputs}>
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Transaktion id"
                    value={shownOrder.transactionData?.id}
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Sähköposti"
                    value={shownOrder.transactionData?.email}
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Tilauksen arvo yhteensä"
                    value={this.getTotalPrice(
                      shownOrder.transactionData?.ticketData
                    )}
                  />
                </div>
                <DataGrid
                  rows={shownOrder.transactionData?.ticketData}
                  columns={this.orderSliderColumns}
                  checkboxSelection={false}
                  getRowId={(row) => row.name}
                  paginationMode="client"
                  editMode="client"
                  pageSize={100}
                  disableSelectionOnClick
                  rowsPerPageOptions={[]}
                />
              </div>
            )}
          </div>
        </Drawer>
        <Dialog
          fullScreen={false}
          open={!!confirmDialogRow}
          onClose={this.closeConfirmDialog}
          className={styles.confirmDialog}
        >
          <DialogTitle className={styles.confirmDialogTitle}>
            {"Poista koodi"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Haluatko varmasti poistaa tämän pääsykoodin? Sitä ei poiston
              jälkeen voida enää käyttää sisäänkirjautumiseen.
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.confirmDialogActions}>
            <Button
              autoFocus
              className={styles.cancel}
              onClick={this.closeConfirmDialog}
              color="primary"
            >
              Peruuta
            </Button>
            <Button
              onClick={() => this.removeCode(confirmDialogRow)}
              color="secondary"
              autoFocus
            >
              Poista
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(muiStyles)(withSnackbar(Codes));
